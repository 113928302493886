import { useState, useEffect } from 'react';

/**
 * This hook allows you to easily detect when an element is visible on the screen as well as specify how much of the
 * element should be visible before being considered on screen. Perfect for lazy loading images or triggering
 * animations when the user has scrolled down to a particular section.
 *
 * https://usehooks.com/useOnScreen/
 */
function useOnScreen(ref, rootMargin = '0px', threshold = 0) {
  const [isIntersecting, setIsIntersecting] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, {
      rootMargin,
      threshold
    });
    const {
      current
    } = ref || {};
    if (current) {
      observer.observe(current);
    }
    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, []);
  return isIntersecting;
}

export { useOnScreen };

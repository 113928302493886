import { Children } from 'react';

function getChildByDisplayName({
  children,
  displayName
}) {
  return Children.toArray(children).find(child => child.type.displayName === displayName);
}
/**
 * There is an issue on `Storybook` that prevents setting the `displayName` on any of the `children`.
 * As a result of that, using the `name` property is the only way of getting the corresponding child for each case
 * without breaking the documentation.
 * @see {@link https://github.com/storybookjs/storybook/issues/15401}
 * @todo remove this function once the issue mentioned above is solved.
 */
function getChildByName({
  children,
  name
}) {
  return Children.toArray(children).find(child => child.type.name === name);
}

export { getChildByDisplayName, getChildByName };

import { useContext } from 'react';
import { SwitchContext } from './Switch.context.js';

function useSwitch() {
  const context = useContext(SwitchContext);
  if (!context) {
    throw new Error('useSwitch consumer must be wrapped by SwitchProvider at some level');
  }
  return context;
}

export { useSwitch };

const defaultOptions = {
  path: '/'
};
const day = 60 * 60 * 24;
const COOKIES_CONFIG = {
  JWT_TOKEN: {
    name: 'jwt-token',
    options: {
      ...defaultOptions,
      maxAge: 30 * day
    }
  },
  REFRESH_TOKEN: {
    name: 'refresh-token',
    options: {
      ...defaultOptions,
      maxAge: 365 * day
    }
  },
  TRACKING_ID: {
    name: 'tracking_id_v2',
    options: {
      ...defaultOptions,
      maxAge: 365 * day
    }
  },
  LEGACY_TRACKING_ID: {
    name: 'tracking_id',
    options: {
      ...defaultOptions,
      maxAge: 365 * day
    }
  },
  HAS_ACCOUNT: {
    name: 'has_account',
    options: {
      ...defaultOptions,
      maxAge: 365 * day
    }
  },
  EXPERIMENTS: {
    name: 'experiments',
    options: {
      ...defaultOptions,
      maxAge: 30 * day
    }
  },
  REFERRAL_USER: {
    name: 'referral_user',
    options: {
      ...defaultOptions,
      maxAge: 60 * 60 * 2
    }
  },
  REFERRAL_ID: {
    name: 'referral_id',
    options: {
      ...defaultOptions,
      maxAge: 60 * 60 * 2
    }
  },
  // To consume same cookie as redux persist on belong-web from belong-next
  REGION: {
    name: 'persist%3Aregion',
    options: {
      ...defaultOptions,
      maxAge: 365 * day
    }
  },
  MOBILE_APP: {
    name: 'mobile_app',
    options: {
      ...defaultOptions
    }
  },
  PARTNER_KEY: {
    name: 'partner_key',
    options: {
      ...defaultOptions,
      maxAge: 30 * day
    }
  }
};

export { COOKIES_CONFIG };

function formatPhoneNumber(input) {
  // Remove all non-numeric characters
  const cleaned = ('' + input).replace(/\D/g, '');
  // Check the length of the cleaned input to ensure it's a valid US phone number
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  throw new Error('Invalid phone number');
}

export { formatPhoneNumber };

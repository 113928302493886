import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { PATHS } from '@belong/common';
import { Image, Link, Text } from '@belong/ui';
import classNames from 'classnames/bind';
import { FixedMenu } from 'components/FixedMenu/FixedMenu';
import Menu from 'components/Menu/Menu';
import { MENU_TYPES } from 'components/Menu/menuTypes';
import { HOMEOWNER_ACCOUNT_MENU_ITEMS, ALL_USER_MENU } from 'consts/account-menu-items';
import { useFullManagementHomes } from 'hooks/useFullManagementHomes';
import { BASE_PATHS } from 'routes/paths';
import { fetchWealth } from 'store/redux/homeowner-accounts/actions';
import { selectAccountProperties } from 'store/redux/homeowner-accounts/selectors';
import { selectSettings } from 'store/redux/settings';
import { isPriceDropsEnabled } from 'utils/featureFlags';
import styles from './accounts-layout.module.css';

const cx = classNames.bind(styles);

export function HomeownersLayout({ comeFromTheMobileApp, isResident, children }) {
  const dispatch = useDispatch();
  const homeownerProperties = useSelector(selectAccountProperties);
  const [wealth, setWealth] = useState();
  const settings = useSelector(selectSettings);

  const fullManagementHomes = useFullManagementHomes();

  const params = useParams();
  const { section, subSection, propertyId } = params;

  const desktopMenuItems = [];
  let commonMenuItems = [];
  const mobileMenu = [];
  const allUserMenu = [...ALL_USER_MENU];

  const hasFullListingUnit = homeownerProperties?.some((property) =>
    property.units?.some(
      (unit) =>
        unit.basicInfo?.currentMilestone === 'FullListingPublished' ||
        unit.basicInfo?.currentMilestone === 'ListingRepublished'
    )
  );

  if (homeownerProperties?.length) {
    const hasPrimaryOwnerHomes = homeownerProperties.some((property) => property.getPrimaryOwnerUnits().length);
    mobileMenu.push(HOMEOWNER_ACCOUNT_MENU_ITEMS[0]);

    const propertiesMenuItems = [];

    homeownerProperties.forEach((property) => {
      if (property.getUnitsWithAgreements().length) {
        // Check if it has incomplete setup flow
        const isSetupFlowIncomplete = property.units.some((unit) => !unit.isSetupFlowCompleted);

        propertiesMenuItems.push({
          header: property.address.streetAddress,
          key: property.basicInfo.propertyId,
          to: `${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/${property.basicInfo.propertyId}`,
          redIndicator: isSetupFlowIncomplete,
        });
      }
    });

    if (hasPrimaryOwnerHomes) {
      if (isPriceDropsEnabled && hasFullListingUnit) {
        commonMenuItems.push(HOMEOWNER_ACCOUNT_MENU_ITEMS[1]);
      }

      commonMenuItems.push(HOMEOWNER_ACCOUNT_MENU_ITEMS[2]);
      commonMenuItems.push(HOMEOWNER_ACCOUNT_MENU_ITEMS[3]);
    }

    commonMenuItems.push({
      ...HOMEOWNER_ACCOUNT_MENU_ITEMS[4],
      new: wealth?.some((item) => item.flow?.status !== 'Cancelled'),
    });

    const homeownerPropertiesStates = Array.from(
      new Set(homeownerProperties.map((homeownerProperty) => homeownerProperty.address?.state as string))
    );

    const isAtLeastOneHomeWithinInsuraceCoverage = homeownerPropertiesStates.filter(
      (homeownerPropertyState) => settings?.licenses?.[homeownerPropertyState]?.Insurance?.belongLicenseNumber
    );

    if (isAtLeastOneHomeWithinInsuraceCoverage.length) {
      const insuranceMenuItem = HOMEOWNER_ACCOUNT_MENU_ITEMS.find((item) => item.key === 'insurance');

      commonMenuItems.push(insuranceMenuItem);
    }

    commonMenuItems.push(HOMEOWNER_ACCOUNT_MENU_ITEMS[6]);
    commonMenuItems.push(HOMEOWNER_ACCOUNT_MENU_ITEMS[7]);

    if (fullManagementHomes?.length === 0) {
      commonMenuItems = commonMenuItems.filter((item) => item.key !== 'maintenance');
    }

    const hasMultipleHomes = homeownerProperties.flatMap((property) => property.units).length > 1;

    desktopMenuItems.push({
      header: hasMultipleHomes ? 'My Homes' : 'My Home',
      key: 'portfolio',
      menuType: MENU_TYPES.BORDER,
      selected: subSection,
      to: PATHS.HOMEOWNERS_ACCOUNT_PROPERTIES,
      menuItems: [
        {
          header: '',
          menuHideOverFive: true,
          menuType: MENU_TYPES.BORDER_NESTED,
          alwaysOpen: true,
          selected: propertyId,
          menuItems: propertiesMenuItems,
        },
        ...commonMenuItems,
      ],
    });
  }

  mobileMenu.push(...commonMenuItems);

  // In mobile, we don't want Listing Performance to be in the fixed bottom bar
  if (isPriceDropsEnabled && hasFullListingUnit) {
    const itemToMove = mobileMenu.splice(1, 1)[0];
    mobileMenu.splice(3, 0, itemToMove);
  }

  useEffect(() => {
    async function init() {
      const wealthData = await dispatch(fetchWealth());

      setWealth(wealthData);
    }

    init();
  }, [dispatch]);

  return (
    <>
      {!comeFromTheMobileApp && (
        <FixedMenu
          menuItems={[...mobileMenu, ...allUserMenu, ...(isResident ? [HOMEOWNER_ACCOUNT_MENU_ITEMS[9]] : [])]}
          params={params}
        />
      )}
      <div className="container mt-sm lg:mt-lg pb-3xl lg:pb-0">
        <div className={cx('sidebar-wrapper')}>
          <div className={cx('sidebar')}>
            <Menu
              controlled
              menuType={MENU_TYPES.BACKGROUND}
              selected={section}
              menuItems={[...desktopMenuItems, ...allUserMenu]}
            />
            {isResident && (
              <div className="hidden lg:flex flex-1 items-end pl-sm">
                <Link href={HOMEOWNER_ACCOUNT_MENU_ITEMS[9].to}>
                  <div className="flex items-center">
                    <div className={styles.switchAccountContainer}>
                      <Image src="/accounts/switch-account-icon.svg" alt="" />
                    </div>
                    <Text fontWeight="semibold" className="ml-xs normal-case">
                      {HOMEOWNER_ACCOUNT_MENU_ITEMS[9].header}
                    </Text>
                  </div>
                </Link>
              </div>
            )}
          </div>
        </div>
        {children}
      </div>
    </>
  );
}

export const HOME_OCCUPANCY_STRINGS = {
  title: "Let’s talk about your current renters!",
  'subtitle':
    "Please introduce us to your residents over email. In the meantime, let’s get their contact info.",
  'last_time_we_checked.month_to_month':
    'Last time we checked, their lease is month-to-month, and they have not given notice.',
  'last_time_we_checked.specific_date': 'Last time we checked, their lease {endsOrEnded} {date}',
  'plans_changed': 'Ok, please update the details of the lease.',
  "move_in_date": 'Move-In Date (MM/DD/YY)',
  "current_rent.placeholder": 'Current Rent ($/Mo.)',
  month_to_month: 'Month-To-Month',
  "lease_ending_MMYY.placeholder": 'Lease Ending (MM/DD/YY)',
  'gave_notice': 'Ok, when are they planning to move out?',
  'already_moved_out.title': 'Ok, time for us to get to work finding someone new to love your home then!',
  'already_moved_out.subtitle': 'Mind if we ask, when did they move out?',
  'relationship.email_unknown': "I don't know it.",
  'relationship.phone_unknown': "I don't know it.",
  'relationship.email_or_phone_required':
    ': We need at least one contact method for your current residents.',
  'lease_file.title': 'Do you have the current lease ready to upload?',
  'lease_file.subtitle': 'Double check that this lease is up to date and reflects the correct rent amount and utility responsibilities.'
}
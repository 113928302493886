import { useRef, useState, useCallback } from 'react';
import { useScript } from './use-script.js';

const PERSONA_CDN_SCRIPT_URL = 'https://cdn.withpersona.com/dist/persona-v4.8.0.js';
const ON_COMPLETE_SAFE_DELAY = 5000;
function usePersona({
  onScriptLoad,
  onScriptLoadError
}) {
  const {
    isLoading: isLoadingScript,
    loadError: scriptLoadError
  } = useScript({
    src: PERSONA_CDN_SCRIPT_URL,
    onLoad: onScriptLoad,
    onLoadError: onScriptLoadError
  });
  const clientRef = useRef(null);
  const isReady = !isLoadingScript && !scriptLoadError;
  const [isVerifying, setIsVerifying] = useState(false);
  const [isWaitingSafeDelay, setIsWaitingSafeDelay] = useState(false);
  const startVerification = useCallback(({
    inquiryId,
    sessionToken,
    onComplete,
    onError,
    onCancel,
    onEvent,
    onCompleteSafeDelay = ON_COMPLETE_SAFE_DELAY
  }) => {
    if (!isReady) {
      // TODO: HANDLE
      return;
    }
    if (!inquiryId) {
      // TODO: HANDLE
      return;
    }
    if (clientRef.current) {
      clientRef.current.destroy();
      clientRef.current = null;
    }
    if (!!window.Persona) {
      setIsVerifying(true);
      // @ts-ignore:next-line
      clientRef.current = new window.Persona.Client({
        inquiryId,
        sessionToken,
        onReady: () => {
          clientRef.current?.open();
        },
        onComplete: param => {
          setIsVerifying(false);
          setIsWaitingSafeDelay(true);
          setTimeout(() => {
            onComplete(param);
            setIsWaitingSafeDelay(false);
          }, onCompleteSafeDelay);
        },
        onError: param => {
          setIsVerifying(false);
          onError?.(param);
        },
        onCancel: param => {
          setIsVerifying(false);
          onCancel?.(param);
        },
        onEvent: param => {
          onEvent?.(param);
        }
      });
    }
  }, [isReady]);
  return {
    isLoadingPersona: isLoadingScript,
    isVerifying,
    isWaitingSafeDelay,
    startVerification
  };
}

export { usePersona };

import { STEP_NAMES } from 'accounts/constants/steps';
import { HomeDisclosures } from 'accounts/pages/homeowners/setup-flow/[steps]/home-disclosures/home-disclosures';
import { HomeFeatures } from 'accounts/pages/homeowners/setup-flow/[steps]/home-features/home-features';
import { HomeInspectionPrep } from 'accounts/pages/homeowners/setup-flow/[steps]/home-inspection/home-inspection-prep';
import { HomeInspectionSchedule } from 'accounts/pages/homeowners/setup-flow/[steps]/home-inspection/home-inspection-schedule';
import { HomeKeys } from 'accounts/pages/homeowners/setup-flow/[steps]/home-keys/home-keys';
import { HomeListing } from 'accounts/pages/homeowners/setup-flow/[steps]/home-listing/home-listing';
import { HomeOccupancy } from 'accounts/pages/homeowners/setup-flow/[steps]/home-occupancy/home-occupancy';
import { HomePets } from 'accounts/pages/homeowners/setup-flow/[steps]/home-pets/home-pets';
import { HomeResidentExperience } from 'accounts/pages/homeowners/setup-flow/[steps]/home-resident-experience/home-resident-experience';
import { HomeUtilities } from 'accounts/pages/homeowners/setup-flow/[steps]/home-utilities/home-utilities';
import { PropertyFeatures } from 'accounts/pages/homeowners/setup-flow/[steps]/property-features/property-features';
import { PropertyHOA } from 'accounts/pages/homeowners/setup-flow/[steps]/property-hoa/property-hoa';
import { PropertyInsurance } from 'accounts/pages/homeowners/setup-flow/[steps]/property-insurance/property-insurance';
import { UserIdentity } from 'accounts/pages/homeowners/setup-flow/[steps]/user-identity/user-identity';
import { UserPaymentMethods } from 'accounts/pages/homeowners/setup-flow/[steps]/user-payment-methods/user-payment-methods';
import { UserProfile } from 'accounts/pages/homeowners/setup-flow/[steps]/user-profile/user-profile';
import { UserSsn } from 'accounts/pages/homeowners/setup-flow/[steps]/user-ssn/user-ssn';
import { getStepPathByName } from './flow-steps';

export function getSetupFlowSteps(propertyId, unitId) {
  return [
    {
      // Inspection Schedule
      path: getStepPathByName(STEP_NAMES.UnitOccupancy, propertyId, unitId),
      form: HomeOccupancy,
    },
    {
      path: getStepPathByName(STEP_NAMES.PropertyFeatures, propertyId, unitId),
      form: PropertyFeatures,
    },
    {
      path: getStepPathByName(STEP_NAMES.UnitFeatures, propertyId, unitId),
      form: HomeFeatures,
    },
    {
      path: getStepPathByName(STEP_NAMES.UnitInspectionPreferences, propertyId, unitId),
      form: HomeInspectionSchedule,
    },
    {
      path: getStepPathByName(STEP_NAMES.UnitKeys, propertyId, unitId),
      form: HomeKeys,
    },
    {
      path: getStepPathByName(STEP_NAMES.UnitKeysEnhanced, propertyId, unitId),
      form: HomeKeys,
    },
    {
      path: getStepPathByName(STEP_NAMES.UnitInspectionPrep, propertyId, unitId),
      form: HomeInspectionPrep,
    },
    {
      path: getStepPathByName(STEP_NAMES.UnitInspectionSchedule, propertyId, unitId),
      form: HomeInspectionSchedule,
    },

    // Build Listing
    {
      path: getStepPathByName(STEP_NAMES.UnitListing, propertyId, unitId),
      form: HomeListing,
    },
    {
      path: getStepPathByName(STEP_NAMES.UnitPreferencesPets, propertyId, unitId),
      form: HomePets,
    },
    {
      path: getStepPathByName(STEP_NAMES.UnitPreferencesUtilities, propertyId, unitId),
      form: HomeUtilities,
    },
    {
      path: getStepPathByName(STEP_NAMES.UnitDisclosures, propertyId, unitId),
      form: HomeDisclosures,
    },
    {
      path: getStepPathByName(STEP_NAMES.PropertyHoa, propertyId, unitId),
      form: PropertyHOA,
    },

    // Next Level Profile
    {
      path: getStepPathByName(STEP_NAMES.UserProfile, propertyId, unitId),
      form: UserProfile,
    },
    {
      path: getStepPathByName(STEP_NAMES.UnitResidentExperience, propertyId, unitId),
      form: HomeResidentExperience,
    },

    // Get Paid
    {
      path: getStepPathByName(STEP_NAMES.UserIdentity, propertyId, unitId),
      form: UserIdentity,
    },
    {
      path: getStepPathByName(STEP_NAMES.UserSsn, propertyId, unitId),
      form: UserSsn,
    },
    {
      path: getStepPathByName(STEP_NAMES.PropertyInsurance, propertyId, unitId),
      form: PropertyInsurance,
    },
    {
      path: getStepPathByName(STEP_NAMES.UserPaymentMethods, propertyId, unitId),
      form: UserPaymentMethods,
    },
  ];
}

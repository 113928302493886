import { format } from 'date-fns';
import * as dateFnsTz from 'date-fns-tz';

const {
  zonedTimeToUtc,
  format: formatToTimeZone,
  utcToZonedTime
} = dateFnsTz;
function formatDateTime({
  dateTime,
  format: format$1,
  options
}) {
  if (!dateTime) {
    return '';
  }
  const date = typeof dateTime === 'string' ? new Date(dateTime) : dateTime;
  return format(date, format$1, options);
}
function formatUtcDateTimeToTimeZone({
  dateTime,
  format,
  timeZone = 'America/Los_Angeles',
  options = {}
}) {
  if (!dateTime || !format) {
    return '';
  }
  const mergedOptions = {
    ...options,
    timeZone
  };
  return formatToTimeZone(utcToZonedTime(dateTime, timeZone, mergedOptions), format, mergedOptions);
}
function formatZonedDateTimeToUtcDateTime({
  dateTime,
  format,
  timeZone = 'America/Los_Angeles',
  options = {}
}) {
  if (!dateTime) {
    return '';
  }
  return formatDateTime({
    dateTime: zonedTimeToUtc(dateTime, timeZone),
    format,
    options
  });
}
/**
 * @todo Check that the date is in MM/YY format and throw error if not.
 */
function parseMMYYDateToYYYYMMDDFirstDayOfMonth({
  date
}) {
  const [mm, yy] = date.split('/');
  return `20${yy}-${mm}-01`;
}

export { formatDateTime, formatUtcDateTimeToTimeZone, formatZonedDateTimeToUtcDateTime, parseMMYYDateToYYYYMMDDFirstDayOfMonth };

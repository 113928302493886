import { useEffect } from 'react';
import config from 'config/config';

export default function CustomerioAnalyticsScript() {
  useEffect(() => {
    // Load the script dynamically
    const script = document.createElement('script');

    script.innerHTML = `!function(){var i="cioanalytics", analytics=(window[i]=window[i]||[]);if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.setAttribute('data-global-customerio-analytics-key', i);t.src="https://cdp.customer.io/v1/analytics-js/snippet/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._writeKey=key;analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.15.3";
analytics.load(
  //You'll get this key from your JavaScript source setup
  //It is not the same as the API key you get alongside your site ID!
  "${config.customerioAnalyticsWriteKey}",
  // Support in-app messaging
  {
    "integrations": {
      "Customer.io In-App Plugin": {
        siteId: "${config.customerioAnalyticsSiteId}"
      }
    }
  }
);
}}();`;

    script.async = true;

    document.head.appendChild(script);

    // Cleanup function
    return () => {
      document.head.removeChild(script);
    };
  }, []); // Empty dependency array means this runs once on mount

  return null; // No need to render anything
}

import { getApiActionTypesValues } from '../redux_utils';

export const fetchResidentLeases =
  ({ includeAllResidents } = { includeAllResidents: false }) =>
  (dispatch) =>
    dispatch({
      types: getApiActionTypesValues('FETCH_LEASES'),
      promise: ({ client }) => client.get(`/residents/summary?includeAllResidents=${includeAllResidents}`),
      auth: true,
      redirectToHomeOnHide: true,
    });

export const fetchResidentBookmarks = () => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_BOOKMARKS'),
    promise: ({ client }) => client.get('/residents/tours-apps'),
    auth: true,
  });

export const postResidentBookmarks = (tourId) => async (dispatch) => {
  await dispatch({
    promise: ({ client }) =>
      client.post('/residents/tours', {
        tourId,
      }),
    auth: true,
    redirectToHomeOnHide: true,
  });

  await dispatch(fetchResidentBookmarks());
};

export const rescheduleResidentTour = (tourAttendeeId, newTourId) => async (dispatch) => {
  await dispatch({
    promise: ({ client }) =>
      client.post('/tours/reschedule', {
        tourAttendeeUniqueId: tourAttendeeId,
        newTourUniqueId: newTourId,
      }),
    auth: true,
    redirectToHomeOnHide: true,
  });
};

export const updateCancelResidentBookmarkById = (id) => async (dispatch) => {
  await dispatch({
    promise: ({ client }) => client.put(`/residents/tours/${id}/cancel`),
    auth: true,
  });

  await dispatch(fetchResidentBookmarks());
};

export const cancelPrivateTour = (tourUniqueId) => ({
  promise: ({ client }) => client.put(`/tours/cancel/private-tour/${tourUniqueId}`),
  auth: true,
});

// self tour

export const getSelfTourTimes = (homeUniqueId) => ({
  promise: ({ client }) => client.get(`/homes/${homeUniqueId}/tours/SelfTour`),
  auth: true,
});

export const postSelfTour = (tourInfo) => ({
  promise: ({ client }) => client.post(`/residents/tours/self-tour`, tourInfo),
  auth: true,
});

export const createTourCustomRequest = (request) => ({
  promise: ({ client }) => client.post(`/residents/tours/custom-request`, request),
  auth: true,
});

export const updateSelfTour = (tourUniqueId, tourInfo) => ({
  promise: ({ client }) => client.put(`/residents/tours/self-tour/${tourUniqueId}/reschedule`, tourInfo),
  auth: true,
});

export const cancelSelfTour = (tourUniqueId) => ({
  promise: ({ client }) => client.put(`/residents/tours/self-tour/${tourUniqueId}/cancel`),
  auth: true,
});

export const updatePaymentId = (tourUniqueId, paymentAccountId) => ({
  promise: ({ client }) => client.put(`/residents/tours/self-tour/${tourUniqueId}/payment`, { paymentAccountId }),
  auth: true,
});

export const updateTourInterest = (homeId, noLongerInMarket) => ({
  promise: ({ client }) =>
    client.put(`/residents/home/${homeId}/interest-level`, { noLongerInMarket, interestLevel: 'NotInterested' }),
  auth: true,
});

export const fetchCurrentUnitWithLeaseId = (unitId, leaseId) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_CURRENT_UNIT_WITH_LEASE'),
    promise: ({ client }) => client.get(`/residents/units/${unitId}?leaseId=${leaseId}`),
    auth: true,
    redirectToHomeOnHide: true,
  });

export const fetchFavoriteHomes = () => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_FAVORITE_HOMES'),
    promise: ({ client }) => client.get(`/residents/favorites`),
    auth: true,
  });

export const updateFavoriteHome = (homeId, isFavorite) => async (dispatch) => {
  try {
    await dispatch({
      promise: ({ client }) => client.put(`/residents/${homeId}/favorite`, { isFavorite }),
      auth: true,
    });

    await dispatch(fetchFavoriteHomes());
    // used to toggle the animations
    return true;
  } catch (e) {
    console.warn(e);
  }
};

export const fetchFavoriteAccountsHomes = () => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_FAVORITE_ACCOUNT_HOMES'),
    promise: ({ client }) => client.get(`/residents/favorites`),
    auth: true,
  });

export const fetchResidentFlows = () => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_RESIDENT_FLOWS'),
    promise: ({ client }) => client.get(`/residents/flows`),
    auth: true,
  });

export const fetchResidentOccupiedTours = (leaseUniqueId) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_RESIDENT_OCCUPIED_TOURS'),
    promise: ({ client }) => client.get(`/residents/tours/occupied/${leaseUniqueId}`),
    auth: true,
  });

export const cancelResidentOccupiedTours = (tourUniqueId) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('CANCEL_RESIDENT_OCCUPIED_TOURS'),
    promise: ({ client }) => client.put(`/residents/tours/${tourUniqueId}/cancel-occupied`),
    auth: true,
  });

export const fetchTourRequests = () => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_TOUR_REQUESTS'),
    promise: async ({ client }) => {
      const tourRequests = await client.get(`/tour-requests`);

      const tourRequestsWithHomeDetails = await Promise.all(
        tourRequests.map(async (tourRequest) => ({
          homeDetails: await client.get(`/homes/${tourRequest.homeUniqueId}`),
          ...tourRequest,
        }))
      );

      return tourRequestsWithHomeDetails;
    },
    auth: true,
  });

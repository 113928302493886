import { useState } from 'react';
import { ButtonBase, Image, Text } from '@belong/ui';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import PostInspectionContentLayout from '../../../PostInspectionContentLayout/PostInspectionContentLayout';
import { getPricingAssignedEmployee } from '../utils';
import css from './Strategy.module.css';

const GridItem = ({
  image,
  title,
  description,
  isBig,
}: {
  image: string;
  title: string;
  description: string;
  isBig?: boolean;
}) => {
  const [showDescription, setShowDescription] = useState(false);

  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  // There is a bug where the images are not showing up in the correct resolution
  // Couldn't find the reason why, so we disable srcSet to fix it
  const imageFixProps = {
    srcSet: '',
    'data-srcset': '',
  };

  return (
    <div
      className={clsx(
        `${isBig ? 'md:col-span-6' : 'md:col-span-4'}`,
        'cursor-pointer relative w-full h-7xl md:h-auto flex flex-col',
        { [css.isOpen]: showDescription },
        css.gridItem
      )}
      key={title}
      onClick={toggleDescription}
    >
      <div className={clsx(css.cardInner)}>
        <div className={clsx(css.cardFront, 'flex flex-col flex-grow justify-center items-center overflow-hidden')}>
          <div className="flex flex-grow justify-center items-center overflow-hidden">
            <Image className={clsx('max-w-full max-h-full object-contain')} src={image} alt="" {...imageFixProps} />
          </div>
          <Text className="mt-sm md:mt-lg text-center" variant="body" fontWeight="semibold">
            {title}
          </Text>
        </div>
        <div className={clsx(css.cardBack, 'bg-light-gray')}>
          <Text variant="body" fontWeight="regular">
            {description}
          </Text>
        </div>
      </div>
    </div>
  );
};

const Strategy = ({ stepResource, onSaveAndNext, stepConfigs, stepConfig, onPrevious }) => {
  const assignedEmployee = getPricingAssignedEmployee(stepResource);

  const gridItems = [
    {
      image: '/pricing-flow/major_sites.svg',
      title: POST_INSPECTION_FLOW_STRINGS['pricing.strategy.all-major-sites'],
      description: POST_INSPECTION_FLOW_STRINGS['pricing.strategy.all_major_sites_description'],
      isBig: true,
    },
    {
      image: '/pricing-flow/touring_your_home.png',
      title: POST_INSPECTION_FLOW_STRINGS['pricing.strategy.touring-your-home'],
      description: POST_INSPECTION_FLOW_STRINGS['pricing.strategy.touring-your-home_description'],
    },
    {
      image: '/pricing-flow/zillow_preferential_partnership.svg',
      title: POST_INSPECTION_FLOW_STRINGS['pricing.strategy.zillow-preferential-partnership'],
      description: POST_INSPECTION_FLOW_STRINGS['pricing.strategy.zillow-preferential-partnership_description'],
    },
    {
      image: '/pricing-flow/dedicated_team_outreach.png',
      title: POST_INSPECTION_FLOW_STRINGS['pricing.strategy.dedicated-team-outreach'],
      description: POST_INSPECTION_FLOW_STRINGS['pricing.strategy.dedicated-team-outreach_description'],
      isBig: true,
    },
  ];

  return (
    <>
      <PostInspectionContentLayout
        showCityBackground={false}
        noOverlay
        stepConfigs={stepConfigs}
        stepConfig={stepConfig}
        title={POST_INSPECTION_FLOW_STRINGS['pricing.strategy.title']}
        employee={assignedEmployee}
      >
        <div className="grid grid-cols-1 md:grid-cols-10 gap-sm mt-2xl mb-3xl">
          {gridItems.map((gridItem) => (
            <GridItem key={gridItem.title} {...gridItem} />
          ))}
        </div>
        <div className="mb-2xl">
          <Text variant="h3" fontWeight="semibold" className="mb-xs">
            {POST_INSPECTION_FLOW_STRINGS['pricing.strategy.price-adjustment-title']}
          </Text>
          <Text className="mb-lg">{POST_INSPECTION_FLOW_STRINGS['pricing.strategy.price-adjustment-description']}</Text>
          <Image className="mb-xl sm:hidden" src="/pricing-flow/price_adjustments_graph_mobile.svg" alt="" />
          <Image className="mb-xl sm:block hidden" src="/pricing-flow/price_adjustments_graph.svg" alt="" />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-lg">
            <div className="col-span-1">
              <Text fontWeight="semibold" className="mb-xs">
                {POST_INSPECTION_FLOW_STRINGS['pricing.strategy.first-4-days-title']}
              </Text>
              <Text variant="p1">{POST_INSPECTION_FLOW_STRINGS['pricing.strategy.first-4-days-description']}</Text>
            </div>
            <div className="col-span-1">
              <Text fontWeight="semibold" className="mb-xs">
                {POST_INSPECTION_FLOW_STRINGS['pricing.strategy.capped-title']}
              </Text>
              <Text variant="p1">{POST_INSPECTION_FLOW_STRINGS['pricing.strategy.capped-description']}</Text>
            </div>
          </div>
          <div className="border-solid border border-green bg-green-light-bg rounded p-sm flex gap-xs items-start md:items-center mt-xl">
            <div style={{ width: 60 }} className="flex-shrink-0">
              <Image src="/pricing-flow/pricing_report_ic_noti.svg" alt="" />
            </div>
            <Text>{POST_INSPECTION_FLOW_STRINGS['pricing.strategy.notified-callout']}</Text>
          </div>
        </div>
        <div className="hidden md:flex justify-center">
          <PostInspectionContentLayout.CtaButton onClick={() => onSaveAndNext()}>
            {POST_INSPECTION_FLOW_STRINGS['pricing.strategy.cta']}
          </PostInspectionContentLayout.CtaButton>
        </div>

        <div className="fixed bottom-0 w-full flex md:hidden -ml-2sm z-fullscreen">
          <ButtonBase className="h-2xl flex-1 bg-navy" onClick={onPrevious}>
            <Text fontWeight="semibold" className="text-white">
              Previous
            </Text>
          </ButtonBase>

          <ButtonBase className="h-2xl flex-1 bg-green disabled:bg-gray" onClick={() => onSaveAndNext()}>
            <Text fontWeight="semibold" className="text-white">
              {POST_INSPECTION_FLOW_STRINGS['pricing.strategy.cta']}
            </Text>
          </ButtonBase>
        </div>
      </PostInspectionContentLayout>
    </>
  );
};
Strategy.propTypes = {
  stepResource: PropTypes.object.isRequired,
  onSaveAndNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  stepConfigs: PropTypes.arrayOf(PropTypes.object).isRequired,
  stepConfig: PropTypes.object.isRequired,
};

export default Strategy;

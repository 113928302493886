import styles from './Switch.module.css.js';

const ROOT_BACKGROUND_STYLES = 'bg-light-gray radix-state-checked:bg-green-translucent group-hover/wrapper:radix-state-checked:bg-lighter-green radix-disabled:radix-state-checked:bg-light-gray group-hover/wrapper:radix-state-checked:radix-disabled:bg-light-gray';
const ROOT_BORDER_STYLES = 'border border-solid border-gray radix-state-checked:border-green group-hover/wrapper:border-navy group-hover/wrapper:radix-state-checked:border-dark-green radix-disabled:radix-state-checked:border-gray group-hover/wrapper:radix-disabled:border-gray group-hover/wrapper:radix-state-checked:radix-disabled:border-gray focus-visible:border-navy focus-visible:radix-state-checked:border-dark-green';
const THUMB_BACKGROUND_STYLES = 'bg-navy group-radix-disabled:bg-gray radix-state-checked:bg-green group-hover/wrapper:bg-light-dark-navy group-hover/wrapper:radix-state-checked:bg-dark-green group-hover/wrapper:radix-state-checked:radix-disabled:bg-gray group-focus-visible:bg-light-dark-navy group-focus-visible:radix-state-checked:bg-dark-green';
const WRAPPER_SIZE_MAP = {
  default: styles.defaultWrapper,
  large: styles.largeWrapper
};
const ROOT_SIZE_MAP = {
  default: styles.defaultRoot,
  large: styles.largeRoot
};
const THUMB_SIZE_MAP = {
  default: styles.defaultThumb,
  large: styles.largeThumb
};
const THUMB_TRANSLATION_MAP = {
  default: styles.defaultThumbTranslation,
  large: styles.largeThumbTranslation
};

export { ROOT_BACKGROUND_STYLES, ROOT_BORDER_STYLES, ROOT_SIZE_MAP, THUMB_BACKGROUND_STYLES, THUMB_SIZE_MAP, THUMB_TRANSLATION_MAP, WRAPPER_SIZE_MAP };

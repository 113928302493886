import Condition from 'formcomponents/Condition/Condition';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { AvailableForRentOptions } from 'models/enums';
import PropTypes from 'prop-types';
import { RENTAL_STATUS_TEXT } from 'strings/rental-status';
import { AvailableForRentInAMonth } from '../AvailableForRentInAMonth/AvailableForRentInAMonth';
import { AvailableForRentOnASpecificDate } from '../AvailableForRentOnASpecificDate/AvailableForRentOnASpecificDate';
import { AvailableForRentOptionsFields } from '../AvailableForRentOptionsFields/AvailableForRentOptionsFields';
import { VacantAvailabilitySection } from '../VacantAvailabilitySection/VacantAvailabilitySection';

const availableForRentOptionSelectors = [
  {
    label: 'On A Specific Date',
    key: AvailableForRentOptions.OnSpecificDate,
  },
  {
    label: 'In A Certain Month',
    key: AvailableForRentOptions.InCertainMonth,
  },
  {
    label: 'It already is!',
    key: AvailableForRentOptions.AlreadyIs,
  },
];

export const StandaloneVacantMoveOutSection = ({ name }) => (
  <>
    <FormLayout.Section sectionTitle={RENTAL_STATUS_TEXT.vacantMoveout}>
      <AvailableForRentOptionsFields name={name} buttons={availableForRentOptionSelectors} />
      <Condition is={AvailableForRentOptions.OnSpecificDate} when={`${name}.availableForRentOption`}>
        <AvailableForRentOnASpecificDate name={name} placeholder={RENTAL_STATUS_TEXT.vacantMoveOutDatePlaceholder} />
      </Condition>
      <AvailableForRentInAMonth name={name} />
    </FormLayout.Section>
    <Condition when={`${name}.availableForRentOption`} isNot={AvailableForRentOptions.AlreadyIs}>
      <VacantAvailabilitySection name={name} />
    </Condition>
  </>
);

StandaloneVacantMoveOutSection.propTypes = {
  name: PropTypes.string.isRequired,
};

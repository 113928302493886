import { getRegionById, INVALID_ZIPCODE } from 'consts/address';
import { isNil, find, isEmpty, isString } from 'es-toolkit/compat';
import { HomeownerCreationSource } from 'models/enums';
import { sessionRegister } from 'store/redux/user/actions';
import { objectInsertIf } from 'utils/insertIf';
import { GA_ACTIONS, trackHomeownerLeadCreated } from '../../../analytics/ga.utils';
import { Experiments } from '../../../experiments/experiment-configs/experiments';
import { getStepConfigByName } from '../../../pages/HomeOwnerOnboarding/Steps/steps';
import { selectExperiments } from '../experiments';
import { getApiActionTypesValues } from '../redux_utils';
import { ACTIONS } from './constants';
import { selectAddresses } from './selectors';

export const updateAddresses = (addresses) => ({
  type: ACTIONS.UPDATE_PROPERTY_ADDRESSES,
  addresses,
});

export const deleteAddresses = () => ({
  type: ACTIONS.DELETE_PROPERTY_ADDRESSES,
});

const getPropertyAddresses = (addresses) => {
  const propertyAddresses = [];

  addresses.forEach((address) => {
    if (isEmpty(address)) {
      return;
    }

    const initialAddress = {
      streetAddress: address.streetAddress,
      city: address.city,
      state: address.state,
      zipcode: address.zipcode !== '' ? address.zipcode : INVALID_ZIPCODE,
      coordinates: {
        latitude: address.latitude,
        longitude: address.longitude,
      },
    };

    if (address.units && address.units.tags?.length) {
      address.units.tags.forEach((unit) => {
        propertyAddresses.push({
          ...initialAddress,
          unitNumber: unit.name,
        });
      });
    } else {
      propertyAddresses.push(initialAddress);
    }
  });

  return propertyAddresses;
};

export const checkAddressCoverage = (addresses) => (dispatch) => {
  const propertyAddresses = getPropertyAddresses(addresses);

  if (propertyAddresses.length) {
    return dispatch({
      types: getApiActionTypesValues('CHECK_COVERAGE_AREA'),
      promise: ({ client }) => client.post('/homeowners/leads/checkcoverage', propertyAddresses),
    });
  }
};

export const _createProperties =
  ({ addresses, creationSource = HomeownerCreationSource.SignUpFlow, isPricingShown }, metadata = {}) =>
  async (dispatch, getState) => {
    const properties = [];
    const experiments = selectExperiments(getState());
    const abTests = [];

    Object.keys(experiments).forEach((name) => {
      const experimentConfig = find(Experiments, { name });

      if (!experimentConfig) {
        return;
      }

      const variationConfig = find(experimentConfig.variations, { value: experiments[name] });

      abTests.push({
        name,
        variation: experiments[name],
        createdOn: experimentConfig.createdOn,
        description: variationConfig.description,
        timestamp: new Date(),
      });
    });

    addresses.forEach((address) => {
      if (isEmpty(address)) {
        return;
      }

      // Format units
      let units;
      const unitsOrTags = address.units?.tags || address.units;
      if (!Array.isArray(unitsOrTags) || unitsOrTags.length === 0) {
        units = [
          {
            unitNumber: '',
          },
        ];
      } else {
        units = unitsOrTags.map((unit) => {
          if (isString(unit)) {
            return { unitNumber: unit };
          } else if (isString(unit.name)) {
            return { unitNumber: unit.name };
          }
          return unit;
        });
      }

      const property = {
        address: {
          streetAddress: address.streetAddress,
          city: address.city,
          state: address.state,
          zipcode: address.zipcode !== '' ? address.zipcode : INVALID_ZIPCODE,
          externalId: address.externalId,
          externalProviderData: address.externalProviderData,
        },
        units,
      };

      if (address.latitude && address.longitude) {
        property.address.coordinates = {
          latitude: address.latitude,
          longitude: address.longitude,
        };
      }

      properties.push(property);
    });

    const onboardingInfo = await dispatch({
      ...metadata,
      auth: true,
      promise: async ({ client }) =>
        client.post('/homeowners/properties/onboarding', {
          properties,
          creationSource,
          abTests,
          ...objectInsertIf(!isNil(isPricingShown), { isPricingShown }),
        }),
    });
    return onboardingInfo;
  };

export const createHomeownerLeads = (user, addresses, creationSource) => async (dispatch, getState) => {
  await dispatch(sessionRegister(user));

  // Get from Onboarding reducer if not provided.
  const response = await dispatch(
    _createProperties({ addresses: addresses || selectAddresses(getState()), creationSource })
  );
  return response;
};

export const createProperties =
  (addresses, creationSource = HomeownerCreationSource.SignUpFlow, isPricingShown) =>
  async (dispatch) => {
    const onboardingInfo = await dispatch(
      _createProperties(
        { addresses, creationSource, isPricingShown },
        { types: getApiActionTypesValues('CREATE_PROPERTIES') }
      )
    );
    dispatch(deleteAddresses());

    try {
      let action = null;
      if (creationSource === HomeownerCreationSource.SignUpFlow) {
        action = GA_ACTIONS.LEAD_CREATED_SIGNUP_FLOW;
      }
      if (creationSource === HomeownerCreationSource.Calculator) {
        action = GA_ACTIONS.LEAD_CREATED_CALCULATOR;
      }
      onboardingInfo.properties.forEach((property) => {
        const { units, address, basicInfo } = property;
        units.forEach((unit) => {
          let coverageType = 'out_of_coverage';
          let region;
          if (basicInfo?.inCoverageArea) {
            region = getRegionById(basicInfo?.coverageRegionId || -1);
            if (basicInfo.isInPrimaryCoverageArea) {
              coverageType = 'primary_zipcode';
            } else {
              coverageType = 'secondary_zipcode';
            }
          }

          trackHomeownerLeadCreated({
            action,
            address,
            rent: unit.estimatedPrice?.rent,
            coverageType,
            region: region?.slug,
          });
        });
      });
    } catch (e) {
      console.warn(e);
    }

    return onboardingInfo;
  };

export const fetchOnboardingProperties = () => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_ONBOARDING_PROPERTIES'),
    promise: ({ client }) => client.get('/homeowners/properties/onboarding'),
    auth: true,
  });

export const fetchSignupFlow = () => ({
  promise: ({ client }) => client.post('/homeowners/sign-up'),
  auth: true,
});

export const fetchSignupFlowStepData = (flowId, step) => (dispatch) => {
  const stepConfig = getStepConfigByName(step.stepName);
  return dispatch({
    promise: ({ client }) =>
      client.get(`/homeowners/sign-up/${flowId}/${stepConfig.group}/${stepConfig.key}/${step.id}`),
    auth: true,
  });
};

export const updateSignupFlowStepData = (flowId, step, data) => (dispatch) => {
  const stepConfig = getStepConfigByName(step.stepName);
  return dispatch({
    promise: ({ client }) =>
      client.put(`/homeowners/sign-up/${flowId}/${stepConfig.group}/${stepConfig.key}/${step.id}`, data),
    auth: true,
  });
};

export const fetchOnboardingInfo = () => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_ONBOARDING_INFO'),
    promise: ({ client }) => client.get('/homeowners/properties/onboarding-info'),
    auth: true,
  });

export const fetchIsSelfServeEligible =
  ({ flowId }) =>
  (dispatch) =>
    dispatch({
      types: getApiActionTypesValues('FETCH_IS_SELF_SERVE_ELIGIBLE'),
      promise: ({ client }) => client.get(`/homeowners/sign-up/${flowId}/is-self-serve-eligible`),
      auth: true,
    });

export const updateOnboardingInfo = (onboardingInfo) => async (dispatch) => {
  await dispatch({
    types: getApiActionTypesValues('UPDATE_ONBOARDING_INFO'),
    promise: ({ client }) =>
      client.put('homeowners/properties/onboarding-info', onboardingInfo).then(() => dispatch(fetchOnboardingInfo())),
  });
};

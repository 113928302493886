import { useEffect, useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { BREAKPOINTS_WIDTHS, useWindowSize } from '@belong/common';
import { ButtonBase, Text } from '@belong/ui';
import clsx from 'clsx';
import config from 'config/config';
import { useIntercom } from 'hooks/useIntercom';
import { isWindowOpenSelector } from 'store/redux/ui/chat-widget/selectors';
import { selectUser } from 'store/redux/user/selectors';
import css from './ChatWidget.module.css';

const { intercomId } = config;

type ChatWidgetProps = {
  shouldShow?: boolean;
  isEnabled: boolean;
  initializeOpen?: boolean;
  shouldHideHelp?: boolean;
  shouldShowInboxLabel?: boolean;
};

export function ChatWidget({
  isEnabled,
  shouldShow = true,
  initializeOpen = false,
  shouldHideHelp = false,
}: ChatWidgetProps) {
  const [showHelpLabel, setShowHelpLabel] = useState(true);
  const isWindowOpen = useSelector(isWindowOpenSelector);
  const user = useSelector(selectUser);
  const { width } = useWindowSize();
  const isMobile = width <= BREAKPOINTS_WIDTHS.LG;
  const showWidget = shouldShow && isEnabled;

  useIntercom(intercomId, initializeOpen);

  const onOpenChat = useCallback(() => {
    const userData = user
      ? {
          user_id: user?.userId,
          user_hash: user?.webIntercomUserHmac,
        }
      : {};

    window.Intercom?.('update', {
      hide_default_launcher: isMobile,
      ...userData,
    });

    window.Intercom?.('onHide', () => {
      setShowHelpLabel(true);
    });

    window.Intercom?.('onShow', () => {
      setShowHelpLabel(false);
    });
  }, [user, isMobile]);

  // Opening the window from navbar using redux
  useEffect(() => {
    if (isWindowOpen) {
      onOpenChat();
    }
  }, [isWindowOpen, onOpenChat]);

  useEffect(() => {
    onOpenChat();
  });

  if (!showWidget) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={clsx('fixed right-xl bottom-xl z-50', css.chatBox)}>
      {showHelpLabel && !shouldHideHelp && (
        <div className={css.buttonContainer}>
          <ButtonBase
            className={clsx('flex flex-col justify-center items-end', css.button)}
            onClick={() => window.Intercom?.('show')}
          >
            <Text className="pr-2sm text-dark-green" fontWeight="semibold">
              NEED HELP?
            </Text>
          </ButtonBase>
        </div>
      )}
      <div className={clsx('absolute inset-0', css.placeholder)} />
    </div>,
    document.getElementById('intercom-widget')
  );
}

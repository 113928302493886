import { useState, useEffect } from 'react';

function useScript({
  src,
  onLoad,
  onLoadError
}) {
  const [scriptState, setScriptState] = useState({
    isLoading: true,
    error: null
  });
  useEffect(() => {
    const script = document.createElement('script');
    script.src = src;
    const handleLoad = () => {
      setScriptState(prevState => ({
        ...prevState,
        isLoading: false
      }));
      onLoad?.();
    };
    const handleError = err => {
      setScriptState({
        isLoading: false,
        error: err
      });
      onLoadError?.(err);
    };
    script.addEventListener('load', handleLoad);
    script.addEventListener('error', handleError);
    document.body.appendChild(script);
    return () => {
      script.removeEventListener('load', handleLoad);
      script.removeEventListener('error', handleError);
      document.body.removeChild(script);
    };
  }, []);
  return {
    isLoading: scriptState.isLoading,
    loadError: scriptState.error
  };
}

export { useScript };

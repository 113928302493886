import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { PATHS, useIsClientReady } from '@belong/common';
import classNames from 'classnames/bind';
import Button, { BUTTON_TYPES } from 'components/Button/Button';
import { CheckboxFinalFormAdapter } from 'components/Checkbox/Checkbox';
import DocumentCard from 'components/DocumentCard/DocumentCard';
import Field from 'components/Field/Field';
import { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import IconButton from 'components/IconButton/IconButton';
import ModalV2 from 'components/Modal/ModalV2/ModalV2';
import PDFViewer from 'components/PDFViewer/PDFViewer';
import TabBar from 'components/TabBar/TabBar';
import { Text } from 'design-system';
import { LeaseRenderer } from 'lease-signing-flow/components/lease-renderer/lease-renderer';
import { LEASE_SIGNING_FLOW_STRINGS } from 'lease-signing-flow/constants/strings';
import AgreementFlowFormLayout from 'pages/AgreementFlow/AgreementFlowFormLayout/AgreementFlowFormLayout';
import { fetchAgreementPdfUrl } from 'store/redux/agreement-flow/actions';
import { fetchOfferedLeases, signOfferedLease } from 'store/redux/resident-onboarding/actions';
import { requireTrue } from 'utils/validation';
import { SeattleSignStep } from './seattle-sign-step';
import styles from './sign-step.module.css';

const cx = classNames.bind(styles);

const tabItems = [
  {
    text: `Lease`,
    key: 0,
  },
  {
    text: `Addendums`,
    key: 1,
  },
];

const initialValues = {};

type Props = {
  currentStepFormData?: unknown;
  home: {
    leaseOffered?: {
      renewalNumber?: number;
    };
  };
  lease?: {
    leaseInfo?: {
      residents?: unknown[];
    };
  };
  onSubmit: () => void;
  setLoading: (isLoading: boolean) => void;
  setSidebarTip: () => void;
  openTipModal: () => void;
};

export const SignStep = ({ home, lease, onSubmit, setLoading, setSidebarTip, openTipModal, ...rest }: Props) => {
  const history = useHistory();
  const isClientReady = useIsClientReady();
  const [selectedTab, setSelectedTab] = useState(tabItems[0]);
  const [offeredLease, setOfferedLease] = useState();
  const [currentPdf, setCurrentPdf] = useState<string | null>(null);
  const [isPDFModalOpen, setIsPDFModalOpen] = useState(false);

  const dispatch = useDispatch();

  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await dispatch(fetchOfferedLeases());

      setOfferedLease(response);
    } catch (e) {
      const isRoomateSigningBeforePrimaryError = e?.[0].errorCode === 'PRIMARY_MUST_SIGN_FIRST';

      if (isRoomateSigningBeforePrimaryError) {
        history.push(`${PATHS.RESIDENTS_ACCOUNT_TOURS_AND_APPS}`);
      }
      console.error(e);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDownloadClick = async () => {
    setLoading(true);

    try {
      const response = await dispatch(fetchAgreementPdfUrl(offeredLease?.leaseDocumentInstance?.uniqueId));

      if (response.url) {
        window.open(response.url, '_blank');
      }
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      await dispatch(signOfferedLease());

      onSubmit();
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  };

  const handleSelectedTab = async (tab) => {
    await fetchData();

    setSelectedTab(tab);
  };

  function handleClosePDFModal() {
    setIsPDFModalOpen(false);
    setCurrentPdf(null);
  }

  if (!offeredLease) {
    return null;
  }

  if (offeredLease?.signMode === 'External') {
    return (
      <SeattleSignStep
        handleSubmit={handleSubmit}
        handleDownload={handleDownloadClick}
        residents={lease?.leaseInfo.residents.length || 1}
        {...rest}
      />
    );
  }

  return (
    <>
      <AgreementFlowFormLayout
        {...rest}
        onSubmit={handleSubmit}
        title={
          home.leaseOffered?.renewalNumber > 0
            ? LEASE_SIGNING_FLOW_STRINGS['sign.title-renewal']
            : LEASE_SIGNING_FLOW_STRINGS['sign.title']
        }
        subTitle={LEASE_SIGNING_FLOW_STRINGS['sign.subtitle']}
        initialValues={initialValues}
        ctaProps={{ label: LEASE_SIGNING_FLOW_STRINGS['sign.CTA'] }}
        getForm={() => {
          return (
            <div className={cx('form-container')}>
              <div className={cx('row')}>
                <TabBar name="lease-tabbar" items={tabItems} onSelect={handleSelectedTab} selected={selectedTab.key} />
                <div className={cx('download-container')}>
                  <div className={cx('desktop')}>
                    <Button
                      onClick={handleDownloadClick}
                      buttonType={BUTTON_TYPES.TEXT}
                      label={LEASE_SIGNING_FLOW_STRINGS['sign.download']}
                    />
                  </div>
                  <div className={cx('mobile')}>
                    <IconButton icon={GENERAL_ICONS.DOWNLOAD} onClick={handleDownloadClick} scale={1} />
                  </div>
                </div>
              </div>
              <div className={cx('agreement-container')}>
                {selectedTab.key === tabItems[0].key && (
                  <LeaseRenderer
                    agreement={offeredLease?.leaseDocumentInstance?.html}
                    setTip={setSidebarTip}
                    openTipModal={openTipModal}
                  />
                )}
                {selectedTab.key === tabItems[1].key && (
                  <div className={cx('addendums-container')}>
                    {offeredLease?.addendums?.map((addendum) => (
                      <DocumentCard
                        bordered={false}
                        size={DocumentCard.SIZES.SMALL}
                        key={addendum.url}
                        onClick={() => {
                          setCurrentPdf(addendum.url);
                          setIsPDFModalOpen(true);
                        }}
                        label={<Text>{addendum.documentType}</Text>}
                        type={addendum.mediaType}
                      />
                    ))}
                  </div>
                )}
              </div>
              <div className={cx('sign-checkbox-container')}>
                <Field
                  name="agreeToElectronicSign"
                  component={CheckboxFinalFormAdapter}
                  label={LEASE_SIGNING_FLOW_STRINGS['sign.checkbox']}
                  validate={requireTrue}
                  firstSection
                />
              </div>
            </div>
          );
        }}
      />
      {isClientReady && (
        <ModalV2
          backdropTheme="dark"
          show={isPDFModalOpen}
          onHide={handleClosePDFModal}
          type="black-background"
          noPadding
        >
          <PDFViewer file={currentPdf} fullScreen />
        </ModalV2>
      )}
    </>
  );
};

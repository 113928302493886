import { Component } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { Form } from 'react-final-form';
import { AppleLogin, GoogleLogin } from '@belong/ui';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import { BUTTON_SIZES, BUTTON_TYPES } from 'components/Button/buttonTypes';
import Field from 'components/Field/Field';
import GeneralIcon, { COLORS, GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { maskRemoveWhiteSpace, unmaskRemoveWhiteSpace } from 'components/Input/masks';
import config from 'config/config';
import Space from 'corecomponents/Space/Space';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import FormLayout from 'layouts/FormLayout/FormLayout';
import PropTypes from 'prop-types';
import { composeValidators, email, required } from 'utils/validation';
import styles from './SignInForm.module.css';

const cx = classNames.bind(styles);

class SignInForm extends Component {
  static title = 'Sign In To Belong';

  static closeButton = true;

  static propTypes = {
    handleLoginSubmit: PropTypes.func.isRequired,
    handleFacebookLoginSubmit: PropTypes.func.isRequired,
    handleGoogleLoginSuccess: PropTypes.func.isRequired,
    handleGoogleLoginFailure: PropTypes.func.isRequired,
    handleAppleCallback: PropTypes.func.isRequired,
    showRegisterScreen: PropTypes.func.isRequired,
    showForgotPasswordEnterEmailScreen: PropTypes.func.isRequired,
    email: PropTypes.string,
    disableEmailInput: PropTypes.bool,
  };

  static defaultProps = {
    email: '',
    disableEmailInput: false,
  };

  render() {
    const {
      handleLoginSubmit,
      handleFacebookLoginSubmit,
      handleGoogleLoginSuccess,
      handleGoogleLoginFailure,
      handleAppleCallback,
      showForgotPasswordEnterEmailScreen,
      showRegisterScreen,
      email: emailInputString,
      disableEmailInput,
    } = this.props;

    const initialValues = { email: emailInputString };

    return (
      <div className={cx('sign-in-content-wrapper')}>
        <Row>
          <Col md={12}>
            <FacebookLogin
              appId={config.facebookAppId}
              autoLoad={false}
              isMobile={false}
              fields="name,email,picture"
              callback={handleFacebookLoginSubmit}
              render={(renderProps) => (
                <div className={cx('sign-in-with-facebook')}>
                  <Button className={cx('facebook-button')} onClick={renderProps.onClick}>
                    <div className={cx('button-content-wrapper')}>
                      <div className={cx('icon-wrapper')}>
                        <GeneralIcon icon={GENERAL_ICONS.FACEBOOK} color={COLORS.WHITE} />
                      </div>
                      <div className={cx('text-wrapper')}>Connect With Facebook</div>
                    </div>
                  </Button>
                </div>
              )}
            />
          </Col>
        </Row>
        <Space.SM />
        <Row>
          <Col md={12}>
            <GoogleLogin
              clientId={config.googleOauthClientId}
              onSuccess={handleGoogleLoginSuccess}
              onFailure={handleGoogleLoginFailure}
            />
          </Col>
        </Row>
        <Space.SM />
        <Row>
          <Col md={12}>
            <div className={cx('apple-login-wrapper')}>
              <AppleLogin redirectURI={config.host} callback={handleAppleCallback} />
            </div>
          </Col>
        </Row>
        <Form
          initialValues={initialValues}
          onSubmit={handleLoginSubmit}
          render={({ handleSubmit, invalid }) => (
            <form onSubmit={handleSubmit}>
              <FormLayout>
                <div className={cx('divider-or-wrapper')}>
                  <div className={cx('divider-line')} />
                  <div>&nbsp;Or&nbsp;</div>
                  <div className={cx('divider-line')} />
                </div>
                <Row>
                  <Col md={12}>
                    <Field
                      name="email"
                      component={InputFinalFormAdapter}
                      placeholder="Email"
                      mask={maskRemoveWhiteSpace}
                      unmask={unmaskRemoveWhiteSpace}
                      validate={composeValidators(email, required)}
                      disabled={disableEmailInput}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Field
                      name="password"
                      type="password"
                      component={InputFinalFormAdapter}
                      placeholder="Password"
                      validate={required}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Button
                      className={cx('forgot-password', 'text-button')}
                      type="button"
                      buttonType={BUTTON_TYPES.TEXT}
                      label="FORGOT PASSWORD?"
                      onClick={showForgotPasswordEnterEmailScreen}
                    />
                  </Col>
                </Row>
                <div className={cx('sign-in-button')}>
                  <Button type="submit" label="Sign in" disabled={invalid} size={BUTTON_SIZES.FLUID} />
                </div>
                <div className={cx('sign-up-section')}>
                  Not a Belong member?&nbsp;
                  <div className={cx('sign-up-button')}>
                    <Button buttonType={BUTTON_TYPES.TEXT} label="SIGN UP" onClick={showRegisterScreen} />
                  </div>
                </div>
              </FormLayout>
            </form>
          )}
        />
      </div>
    );
  }
}

export default SignInForm;

const US_STATES_CODES = {
  AL: 'AL',
  AK: 'AK',
  AS: 'AS',
  AZ: 'AZ',
  AR: 'AR',
  CA: 'CA',
  CO: 'CO',
  CT: 'CT',
  DE: 'DE',
  DC: 'DC',
  FM: 'FM',
  FL: 'FL',
  GA: 'GA',
  GU: 'GU',
  HI: 'HI',
  ID: 'ID',
  IL: 'IL',
  IN: 'IN',
  IA: 'IA',
  KS: 'KS',
  KY: 'KY',
  LA: 'LA',
  ME: 'ME',
  MH: 'MH',
  MD: 'MD',
  MA: 'MA',
  MI: 'MI',
  MN: 'MN',
  MS: 'MS',
  MO: 'MO',
  MT: 'MT',
  NE: 'NE',
  NV: 'NV',
  NH: 'NH',
  NJ: 'NJ',
  NM: 'NM',
  NY: 'NY',
  NC: 'NC',
  ND: 'ND',
  MP: 'MP',
  OH: 'OH',
  OK: 'OK',
  OR: 'OR',
  PW: 'PW',
  PA: 'PA',
  PR: 'PR',
  RI: 'RI',
  SC: 'SC',
  SD: 'SD',
  TN: 'TN',
  TX: 'TX',
  UT: 'UT',
  VT: 'VT',
  VI: 'VI',
  VA: 'VA',
  WA: 'WA',
  WV: 'WV',
  WI: 'WI',
  WY: 'WY'
};
const US_STATES = {
  [US_STATES_CODES.AL]: {
    code: US_STATES_CODES.AL,
    name: 'Alabama'
  },
  [US_STATES_CODES.AK]: {
    code: US_STATES_CODES.AK,
    name: 'Alaska'
  },
  [US_STATES_CODES.AS]: {
    code: US_STATES_CODES.AS,
    name: 'American Samoa'
  },
  [US_STATES_CODES.AZ]: {
    code: US_STATES_CODES.AZ,
    name: 'Arizona'
  },
  [US_STATES_CODES.AR]: {
    code: US_STATES_CODES.AR,
    name: 'Arkansas'
  },
  [US_STATES_CODES.CA]: {
    code: US_STATES_CODES.CA,
    name: 'California'
  },
  [US_STATES_CODES.CO]: {
    code: US_STATES_CODES.CO,
    name: 'Colorado'
  },
  [US_STATES_CODES.CT]: {
    code: US_STATES_CODES.CT,
    name: 'Connecticut'
  },
  [US_STATES_CODES.DE]: {
    code: US_STATES_CODES.DE,
    name: 'Delaware'
  },
  [US_STATES_CODES.DC]: {
    code: US_STATES_CODES.DC,
    name: 'District of Columbia'
  },
  [US_STATES_CODES.FM]: {
    code: US_STATES_CODES.FM,
    name: 'Federated States of Micronesia'
  },
  [US_STATES_CODES.FL]: {
    code: US_STATES_CODES.FL,
    name: 'Florida'
  },
  [US_STATES_CODES.GA]: {
    code: US_STATES_CODES.GA,
    name: 'Georgia'
  },
  [US_STATES_CODES.GU]: {
    code: US_STATES_CODES.GU,
    name: 'Guam'
  },
  [US_STATES_CODES.HI]: {
    code: US_STATES_CODES.HI,
    name: 'Hawaii'
  },
  [US_STATES_CODES.ID]: {
    code: US_STATES_CODES.ID,
    name: 'Idaho'
  },
  [US_STATES_CODES.IL]: {
    code: US_STATES_CODES.IL,
    name: 'Illinois'
  },
  [US_STATES_CODES.IN]: {
    code: US_STATES_CODES.IN,
    name: 'Indiana'
  },
  [US_STATES_CODES.IA]: {
    code: US_STATES_CODES.IA,
    name: 'Iowa'
  },
  [US_STATES_CODES.KS]: {
    code: US_STATES_CODES.KS,
    name: 'Kansas'
  },
  [US_STATES_CODES.KY]: {
    code: US_STATES_CODES.KY,
    name: 'Kentucky'
  },
  [US_STATES_CODES.LA]: {
    code: US_STATES_CODES.LA,
    name: 'Louisiana'
  },
  [US_STATES_CODES.ME]: {
    code: US_STATES_CODES.ME,
    name: 'Maine'
  },
  [US_STATES_CODES.MH]: {
    code: US_STATES_CODES.MH,
    name: 'Marshal Islands'
  },
  [US_STATES_CODES.MD]: {
    code: US_STATES_CODES.MD,
    name: 'Maryland'
  },
  [US_STATES_CODES.MA]: {
    code: US_STATES_CODES.MA,
    name: 'Massachusetts'
  },
  [US_STATES_CODES.MI]: {
    code: US_STATES_CODES.MI,
    name: 'Michigan'
  },
  [US_STATES_CODES.MN]: {
    code: US_STATES_CODES.MN,
    name: 'Minnesota'
  },
  [US_STATES_CODES.MS]: {
    code: US_STATES_CODES.MS,
    name: 'Mississippi'
  },
  [US_STATES_CODES.MO]: {
    code: US_STATES_CODES.MO,
    name: 'Missouri'
  },
  [US_STATES_CODES.MT]: {
    code: US_STATES_CODES.MT,
    name: 'Montana'
  },
  [US_STATES_CODES.NE]: {
    code: US_STATES_CODES.NE,
    name: 'Nebraska'
  },
  [US_STATES_CODES.NV]: {
    code: US_STATES_CODES.NV,
    name: 'Nevada'
  },
  [US_STATES_CODES.NH]: {
    code: US_STATES_CODES.NH,
    name: 'New Hampshire'
  },
  [US_STATES_CODES.NJ]: {
    code: US_STATES_CODES.NJ,
    name: 'New Jersey'
  },
  [US_STATES_CODES.NM]: {
    code: US_STATES_CODES.NM,
    name: 'New Mexico'
  },
  [US_STATES_CODES.NY]: {
    code: US_STATES_CODES.NY,
    name: 'New York'
  },
  [US_STATES_CODES.NC]: {
    code: US_STATES_CODES.NC,
    name: 'North Carolina'
  },
  [US_STATES_CODES.ND]: {
    code: US_STATES_CODES.ND,
    name: 'North Dakota'
  },
  [US_STATES_CODES.MP]: {
    code: US_STATES_CODES.MP,
    name: 'Northern Mariana Islands'
  },
  [US_STATES_CODES.OH]: {
    code: US_STATES_CODES.OH,
    name: 'Ohio'
  },
  [US_STATES_CODES.OK]: {
    code: US_STATES_CODES.OK,
    name: 'Oklahoma'
  },
  [US_STATES_CODES.OR]: {
    code: US_STATES_CODES.OR,
    name: 'Oregon'
  },
  [US_STATES_CODES.PW]: {
    code: US_STATES_CODES.PW,
    name: 'Palau'
  },
  [US_STATES_CODES.PA]: {
    code: US_STATES_CODES.PA,
    name: 'Pennsylvania'
  },
  [US_STATES_CODES.PR]: {
    code: US_STATES_CODES.PR,
    name: 'Puerto Rico'
  },
  [US_STATES_CODES.RI]: {
    code: US_STATES_CODES.RI,
    name: 'Rhode Island'
  },
  [US_STATES_CODES.SC]: {
    code: US_STATES_CODES.SC,
    name: 'South Carolina'
  },
  [US_STATES_CODES.SD]: {
    code: US_STATES_CODES.SD,
    name: 'South Dakota'
  },
  [US_STATES_CODES.TN]: {
    code: US_STATES_CODES.TN,
    name: 'Tennessee'
  },
  [US_STATES_CODES.TX]: {
    code: US_STATES_CODES.TX,
    name: 'Texas'
  },
  [US_STATES_CODES.UT]: {
    code: US_STATES_CODES.UT,
    name: 'Utah'
  },
  [US_STATES_CODES.VT]: {
    code: US_STATES_CODES.VT,
    name: 'Vermont'
  },
  [US_STATES_CODES.VI]: {
    code: US_STATES_CODES.VI,
    name: 'Virgin Islands'
  },
  [US_STATES_CODES.VA]: {
    code: US_STATES_CODES.VA,
    name: 'Virginia'
  },
  [US_STATES_CODES.WA]: {
    code: US_STATES_CODES.WA,
    name: 'Washington'
  },
  [US_STATES_CODES.WV]: {
    code: US_STATES_CODES.WV,
    name: 'West Virginia'
  },
  [US_STATES_CODES.WI]: {
    code: US_STATES_CODES.WI,
    name: 'Wisconsin'
  },
  [US_STATES_CODES.WY]: {
    code: US_STATES_CODES.WY,
    name: 'Wyoming'
  },
  TT: {
    code: 'TT',
    name: 'Trust Territories'
  },
  NA: {
    code: 'NA',
    name: 'Nationally'
  }
};

export { US_STATES, US_STATES_CODES };

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PATHS, template } from '@belong/common';
import { ResidentAccountSummaryItem } from 'api/models';
import FullPageSpinner from 'components/FullPageSpinner/FullPageSpinner';
import { camelCase } from 'es-toolkit/compat';
import { StepsLayout } from 'lease-signing-flow/components/steps-layout/steps-layout';
import { LEASE_SIGNING_FLOW_STRINGS } from 'lease-signing-flow/constants/strings';
// todo: rename this
import { Landing } from 'lease-signing-flow/pages/landing/landing';
import OldSuccess from 'lease-signing-flow/pages/old-success/old-success';
import { Success as NewSuccess } from 'lease-signing-flow/pages/success/success';
import { fetchLeaseDepositTypes, selectLeaseDepositTypeOptions } from 'lease-signing-flow/store/deposit';
import { fetchLeaseDuration, selectLeaseDuration } from 'lease-signing-flow/store/duration';
import { getMostRecentLease } from 'lease-signing-flow/utils/lease';
import { getAvailableSteps, getCurrentStep, getStepPath } from 'lease-signing-flow/utils/steps';
import { EmployeeAssignmentTypes, LeaseSigningFlowStepNames, LeaseStatus, UserSignatureStatus } from 'models/enums';
import Home from 'models/home/Home';
import Page404 from 'pages/Page404/Page404';
import queryString from 'query-string';
import { BASE_PATHS } from 'routes/paths';
import { fetchHome } from 'store/redux/homes/actions';
import { fetchResidentBookmarks, fetchResidentLeases } from 'store/redux/renter-accounts/actions';
import { selectIsUserLoggedIn, selectUser } from 'store/redux/user/selectors';
import { getString } from 'strings';
import { EMPLOYEE_TITLES } from 'strings/common.strings';
import { LEASE_SIGNING_FLOW_STRINGS as STRINGS } from 'strings/lease-signing-flow.strings';

type RouteParams = {
  homeId?: string;
};

const Flow = () => {
  const [isLoading, setLoading] = useState(false);
  const [isStepLoading, setIsStepLoading] = useState(false);
  const [sidebarTip, setSidebarTip] = useState(null);
  const [home, setHome] = useState<Home>();
  const [lease, setLease] = useState<ResidentAccountSummaryItem>();
  const [leaseWithExecuted, setLeaseWithExecuted] = useState();
  const [, setBookmarks] = useState();

  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const user = useSelector(selectUser);
  const depositTypeOptions = useSelector(selectLeaseDepositTypeOptions);
  const durationOptions = useSelector(selectLeaseDuration);
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();
  const { path, url } = useRouteMatch();
  const { homeId } = useParams<RouteParams>();

  const queryJson = location.search ? queryString.parse(location.search) : null;
  const isCosigner = queryJson?.cosigner === '1';

  const userIdParam = queryJson?.userId;

  const isRenew = home?.leaseOffered?.renewalNumber > 0;

  let employeeAssignment;

  if (isRenew) {
    employeeAssignment = home?.employees?.concierge;
  } else {
    employeeAssignment = home?.employees?.residentGrowth || {};
  }

  employeeAssignment.jobTitle = isRenew
    ? EMPLOYEE_TITLES[EmployeeAssignmentTypes.Concierge]
    : EMPLOYEE_TITLES[EmployeeAssignmentTypes.ResidentGrowth];

  const employee = {
    employeeAssignment: {
      assignmentType: isRenew ? EmployeeAssignmentTypes.Concierge : EmployeeAssignmentTypes.ResidentGrowth,
    },
    employee: {
      ...employeeAssignment,
      email: employeeAssignment?.workEmail,
    },
  };

  const getSidebarTip = (step) => {
    if (sidebarTip) return sidebarTip;

    if (step.name === LeaseSigningFlowStepNames.Sign) {
      const tipDescription = STRINGS[`${step.name}.tips.description`];
      let description = tipDescription[home?.address?.state] || tipDescription.california;

      if (lease?.leaseInfo.basicInfo.signatureInfo?.signMode === 'External') {
        description = tipDescription['WA-notarize'];
      }

      return {
        description: getString(description, {
          firstName: home?.owner?.name,
        }),
      };
    }

    const cameleCaseStepName = camelCase(step.name ?? '');

    return {
      title: LEASE_SIGNING_FLOW_STRINGS[`${cameleCaseStepName}.tip.title`],
      description: LEASE_SIGNING_FLOW_STRINGS[`${cameleCaseStepName}.tip.description`],
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      let homeResponse = null;

      try {
        homeResponse = await dispatch(fetchHome(homeId, false));
      } catch (e) {
        console.error(e);
      }

      if (isUserLoggedIn) {
        if (userIdParam && user && user.userId !== userIdParam) {
          return history.push(
            `${PATHS.RESIDENTS_ACCOUNT_TOURS_AND_APPS}?error=${template(
              LEASE_SIGNING_FLOW_STRINGS['errors.userMismatch'],
              { firstName: user.firstName, lastName: user.lastName }
            )}`
          );
        }

        try {
          const bookmarksResponse = await dispatch(fetchResidentBookmarks());
          setBookmarks(bookmarksResponse.bookmarks);
        } catch (e) {
          console.error(e);
        }

        try {
          const accountLeases = await dispatch(fetchResidentLeases({ includeAllResidents: true }));

          const mostRecentLeaseForHome = getMostRecentLease(accountLeases, homeId, false);

          const externalLeaseAlreadySigned =
            mostRecentLeaseForHome?.leaseInfo?.basicInfo?.signatureInfo?.hasResidentCompletedSigningFlow;

          const isSuccessStep = location.pathname.includes(getStepPath(LeaseSigningFlowStepNames.Success));
          const isAlreadySignedByCurrentResident =
            mostRecentLeaseForHome?.leaseInfo?.residents?.find(
              (resident) => resident?.basicInfo?.userInfo?.userId === user.userId
            )?.leaseInfo?.signatureStatus === 'Signed';

          if (externalLeaseAlreadySigned && !isSuccessStep) {
            return history.push(
              `${PATHS.RESIDENTS_ACCOUNT_TOURS_AND_APPS}?error=${LEASE_SIGNING_FLOW_STRINGS['errors.externalLeaseSigned']}`
            );
          }

          if (isAlreadySignedByCurrentResident && !isSuccessStep) {
            return history.push(
              `${PATHS.RESIDENTS_ACCOUNT_TOURS_AND_APPS}?error=${LEASE_SIGNING_FLOW_STRINGS['errors.alreadySigned']}`
            );
          }

          await dispatch(fetchLeaseDepositTypes());

          const mostRecentLeaseForHomeWithExecuted = getMostRecentLease(accountLeases, homeId, true);

          await dispatch(fetchLeaseDuration(mostRecentLeaseForHome.leaseInfo.basicInfo.leaseId));

          setLease(mostRecentLeaseForHome);
          setLeaseWithExecuted(mostRecentLeaseForHomeWithExecuted);
        } catch (e) {
          console.error(e);
        }
      }

      setHome(new Home(homeResponse));
      setLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoggedIn, location.pathname, homeId]);

  if (typeof window === 'undefined') {
    return null;
  }

  if (!homeId) {
    return <Redirect to={BASE_PATHS.ACCOUNTS} />;
  }

  if (!home) {
    return <FullPageSpinner />;
  }

  // If there is no leaseOffered object or lease is already signed, redirect from landing page to accounts page.
  if (
    location.pathname === url &&
    (!home?.leaseOffered || (home?.leaseOffered?.status === LeaseStatus.Executed && isUserLoggedIn))
  ) {
    return <Redirect to={BASE_PATHS.ACCOUNTS} />;
  }

  const { residents } = lease?.leaseInfo || {};

  // Don't understand why we don't send null when lease is not present, so have to check if residents is not falsy.
  const currentResident = residents?.find((resident) => resident.basicInfo.userInfo.userId === user.userId);

  // If we have lease object (user is logged in), and current user is not in the lase, take it out.
  if (residents?.length && !currentResident) {
    return <Redirect to={BASE_PATHS.ACCOUNTS} />;
  }

  // If the lease is already signed by current resident, take it to success page.
  if (
    residents?.length &&
    location.pathname === `${url}${getStepPath(LeaseSigningFlowStepNames.Sign)}` &&
    currentResident?.leaseInfo?.signatureStatus === UserSignatureStatus.Signed
  ) {
    return <Redirect to={`${url}${getStepPath(LeaseSigningFlowStepNames.Success)}`} />;
  }

  const availableSteps = getAvailableSteps(currentResident, lease, depositTypeOptions, durationOptions);

  const handleNext = (stepIndex: number) => () => {
    const nextStepIndex = stepIndex + 1;
    const nextStep = availableSteps[nextStepIndex];

    if (nextStepIndex === availableSteps.length) {
      if (lease?.leaseInfo.basicInfo.signatureInfo?.signMode === 'External') {
        return history.push(BASE_PATHS.ACCOUNTS);
      }
      return history.push(`${url}${getStepPath(LeaseSigningFlowStepNames.Success)}`);
    }

    history.push(`${url}${nextStep.path}`);
  };

  const handleLandingPageLogin = async (_user) => {
    const [accountLeases, _depositTypeOptions] = await Promise.all([
      dispatch(fetchResidentLeases({ includeAllResidents: true })),
      dispatch(fetchLeaseDepositTypes()),
    ]);

    const mostRecentLeaseForHome = getMostRecentLease(accountLeases, homeId, false);

    const { residents: _residents } = mostRecentLeaseForHome?.leaseInfo || {};
    const _currentResident = _residents?.find((resident) => resident.basicInfo.userInfo.userId === _user.userId);

    const _availableSteps = getAvailableSteps(
      _currentResident,
      mostRecentLeaseForHome,
      _depositTypeOptions.options,
      durationOptions
    );

    const nextStepIndex = 0;
    const nextStep = _availableSteps[nextStepIndex];

    if (nextStepIndex === _availableSteps.length) {
      if (lease?.leaseInfo.basicInfo.signatureInfo?.signMode === 'External') {
        return history.push(BASE_PATHS.ACCOUNTS);
      }
      return history.push(`${url}${getStepPath(LeaseSigningFlowStepNames.Success)}`);
    }

    history.push(`${url}${nextStep.path}`);
  };

  // Initially wanted LeaseSigningFlow to be unaware of currentStep,
  // but gonna need it in the end, let's think about this one better for next flow.
  const currentStep = getCurrentStep(location, availableSteps);

  const steps = availableSteps.map((step) => ({
    ...step,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    path: `${path.replace(':homeId', homeId)}${(step as any).path}`,
  }));

  const isExecuted = leaseWithExecuted?.leaseInfo?.basicInfo?.status === LeaseStatus.Executed;
  const isRenewal = leaseWithExecuted?.leaseInfo?.basicInfo?.renewalNumber > 0;

  return (
    <Switch>
      <Route exact path={path} strict>
        <Landing
          home={home}
          isCosigner={isCosigner}
          lease={lease}
          onLandingPageLogin={handleLandingPageLogin}
          onSubmit={handleNext(-1)}
        />
      </Route>
      <Route strict exact path={`${path}${getStepPath(LeaseSigningFlowStepNames.Success)}`}>
        {!isExecuted || isRenewal ? (
          <OldSuccess home={home} lease={leaseWithExecuted} employee={employeeAssignment} homeId={homeId} />
        ) : (
          <NewSuccess />
        )}
      </Route>
      <StepsLayout
        currentStep={currentStep}
        employeeAssignment={employee}
        home={home}
        isFlowLoading={isLoading || isStepLoading}
        lease={lease}
        setLoading={setIsStepLoading}
        setSidebarTip={setSidebarTip}
        showEmployeeTip={currentStep?.name === LeaseSigningFlowStepNames.Sign}
        sidebarProps={{
          currentStep,
        }}
        onSubmit={handleNext(currentStep.index)}
        steps={steps}
        tip={getSidebarTip(currentStep)}
      />
      <Route path="*">
        <Page404 />
      </Route>
    </Switch>
  );
};

export default Flow;

import { formatMoney, template } from '@belong/common';
import { Text } from '@belong/ui';
import { useSplitTreatments } from '@splitsoftware/splitio-react';
import {
  PRE_MOVE_IN_SPOT_CLEANING_SUMMARY,
  REKEYING_AND_KEY_COPYING_SUMMARY,
  PRE_MOVE_IN_SPOT_CLEANING_PRICE,
  REKEYING_AND_KEY_COPYING_PRICE,
} from 'pages/PostInspectionFlow/steps/Improvements/constants';
import { STRINGS } from 'pages/PostInspectionFlow/steps/Improvements/strings';

type Props = {
  bundleList: {
    items: {
      maintenance: {
        summary: string;
      };
    }[];
  }[];
};

export function RekeyingPreMoveInSpotCleanDisclaimer({ bundleList }: Props) {
  const { treatments, isReady } = useSplitTreatments({ names: ['automatic-pre-move-in-repairs'] });

  if (!isReady) {
    return null;
  }

  const { treatment } = treatments['automatic-pre-move-in-repairs'];

  if (treatment === 'off') {
    return null;
  }

  const improvementSummaries = bundleList.flatMap((bundle) =>
    bundle.items.map((item) => item?.maintenance?.summary.trim().toLowerCase())
  );
  const hasPreMoveInSpotCleaning = improvementSummaries.includes(PRE_MOVE_IN_SPOT_CLEANING_SUMMARY.toLowerCase());
  const hasRekeyingAndKeyCopying = improvementSummaries.includes(REKEYING_AND_KEY_COPYING_SUMMARY.toLowerCase());

  if (hasPreMoveInSpotCleaning && hasRekeyingAndKeyCopying) {
    return null;
  }

  if (!hasPreMoveInSpotCleaning && !hasRekeyingAndKeyCopying) {
    return (
      <div className="my-2xl">
        <div className="border border-solid border-green bg-green-translucent-light rounded p-sm flex flex-col gap-xs">
          <div className="flex justify-between items-center">
            <Text fontWeight="semibold">{STRINGS.rekeyingSpotCleaningDisclaimerTitle}</Text>
            <Text fontWeight="semibold">
              +&nbsp;{formatMoney(PRE_MOVE_IN_SPOT_CLEANING_PRICE + REKEYING_AND_KEY_COPYING_PRICE)}
            </Text>
          </div>
          <Text variant="p1">
            {template(STRINGS.rekeyingSpotCleaningDisclaimer, {
              totalPrice: formatMoney(PRE_MOVE_IN_SPOT_CLEANING_PRICE + REKEYING_AND_KEY_COPYING_PRICE),
            })}
          </Text>
        </div>
      </div>
    );
  }

  if (hasPreMoveInSpotCleaning) {
    return (
      <div className="my-2xl">
        <div className="border border-solid border-green bg-green-translucent-light rounded p-sm flex flex-col gap-xs">
          <div className="flex justify-between items-center">
            <Text fontWeight="semibold">{STRINGS.rekeyingSpotCleaningDisclaimerTitle}</Text>
            <Text fontWeight="semibold">+&nbsp;{formatMoney(REKEYING_AND_KEY_COPYING_PRICE)}</Text>
          </div>
          <Text variant="p1">
            {template(STRINGS.rekeyingDisclaimer, {
              rekeyingPrice: formatMoney(REKEYING_AND_KEY_COPYING_PRICE),
            })}
          </Text>
        </div>
      </div>
    );
  }

  return (
    <div className="my-2xl">
      <div className="border border-solid border-green bg-green-translucent-light rounded p-sm flex flex-col gap-xs">
        <div className="flex justify-between items-center">
          <Text fontWeight="semibold">{STRINGS.rekeyingSpotCleaningDisclaimerTitle}</Text>
          <Text fontWeight="semibold">+&nbsp;{formatMoney(PRE_MOVE_IN_SPOT_CLEANING_PRICE)}</Text>
        </div>
        <Text variant="p1">
          {template(STRINGS.spotCleaningDisclaimer, {
            spotCleaningPrice: formatMoney(PRE_MOVE_IN_SPOT_CLEANING_PRICE),
          })}
        </Text>
      </div>
    </div>
  );
}

import { useState, useEffect } from 'react';
import cx from 'classnames';
import { CheckboxFinalFormAdapter } from 'components/Checkbox/Checkbox';
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { get } from 'es-toolkit/compat';
import PropTypes from 'prop-types';

function InputCheckbox({ form, name, meta, inputProps, checkboxProps, hideCheckboxIfValue }) {
  const lowerCheckboxThreshold = 15;
  const [lowerCheckbox, setLowerCheckbox] = useState(inputProps.placeholder?.length < lowerCheckboxThreshold);
  const { values } = form.getState();

  useEffect(() => {
    setLowerCheckbox(inputProps.placeholder?.length > lowerCheckboxThreshold);
  }, [inputProps.placeholder]);

  const truthy = !!get(values, name)?.checkboxValue;

  useEffect(() => {
    // We need to remove value if we don't hide the checkbox when there is a value selected
    if (truthy && !hideCheckboxIfValue) {
      form.change(`${name}.inputValue`, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [truthy, hideCheckboxIfValue]);

  return (
    <div>
      <div className={cx('relative', { 'pb-xl sm:pb-0': lowerCheckbox })}>
        <Field
          meta={meta}
          disabled={truthy}
          applyDisabledStyle={truthy}
          name={`${name}.inputValue`}
          component={InputFinalFormAdapter}
          {...inputProps}
          validate={truthy ? () => ({}) : inputProps.validate}
          key={truthy ? `${name}.inputValue0` : `${name}.inputValue1`}
          nestedField
        />
        <div
          className={cx(
            'relative text-right',
            { 'bottom-0 sm:bottom-sm': lowerCheckbox },
            { hidden: hideCheckboxIfValue && !!get(values, name)?.inputValue }
          )}
        >
          <Field
            useOnChangeFormToHandle
            name={`${name}.checkboxValue`}
            label="I don't know."
            {...checkboxProps}
            component={CheckboxFinalFormAdapter}
            nestedField
          />
        </div>
      </div>
    </div>
  );
}

// eslint-disable-next-line react/prop-types
export const InputCheckboxAdapter = ({ input, meta, ...rest }) => <InputCheckbox {...rest} {...input} meta={meta} />;

InputCheckbox.propTypes = {
  meta: PropTypes.object.isRequired,
  inputProps: PropTypes.object,
  checkboxProps: PropTypes.object,
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  hideCheckboxIfValue: PropTypes.bool,
};

InputCheckbox.defaultProps = {
  inputProps: {},
  checkboxProps: {},
  hideCheckboxIfValue: true,
};

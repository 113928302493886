import * as React from 'react';

// TODO: Not conviced about name, ask around
function useIsClientReady() {
  const [isClientReady, setIsClientReady] = React.useState(false);
  React.useEffect(() => {
    setIsClientReady(true);
  }, []);
  return isClientReady;
}

export { useIsClientReady };

import { useState } from 'react';
import { Document, Page as PdfPage, pdfjs } from 'react-pdf';
import classNames from 'classnames/bind';
import Spinner from 'components/Spinner/Spinner';
import PropTypes from 'prop-types';
import styles from './PDFViewer.module.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const cx = classNames.bind(styles);

const PDFViewer = ({ file, loading = <Spinner string="Loading PDF" />, fullScreen, ...rest }) => {
  const [numPages, setNumPages] = useState(false);

  const onDocumentLoadSuccess = ({ numPages: newNumPages }) => {
    setNumPages(newNumPages);
  };

  return (
    <Document
      file={file}
      className={cx('document-wrapper', { loading: !numPages }, { fullScreen })}
      onLoadSuccess={onDocumentLoadSuccess}
      onLoadError={console.error}
      loading={loading}
      noData={loading}
      {...rest}
    >
      {new Array(numPages).fill(0).map((_, index) => {
        return <PdfPage key={index} pageNumber={index + 1} renderTextLayer={false} renderAnnotationLayer={false} />;
      })}
    </Document>
  );
};

PDFViewer.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  loading: PropTypes.node,
  fullScreen: PropTypes.bool,
};

export default PDFViewer;

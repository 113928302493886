import { getApiActionTypes } from '../redux_utils';

export const ACTIONS = {
  ...getApiActionTypes('FETCH_LEASES'),
  ...getApiActionTypes('FETCH_BOOKMARKS'),
  ...getApiActionTypes('FETCH_RESIDENT_UNITS'),
  ...getApiActionTypes('FETCH_CURRENT_UNIT_WITH_LEASE'),
  ...getApiActionTypes('FETCH_BOOKMARKS'),
  ...getApiActionTypes('FETCH_PAYMENTS'),
  ...getApiActionTypes('FETCH_PAYMENT_BY_ID'),
  ...getApiActionTypes('UPDATE_MAINTENANCE_TASK'),
  ...getApiActionTypes('FETCH_FAVORITE_HOMES'),
  ...getApiActionTypes('FETCH_RESIDENT_OCCUPIED_TOURS'),
  ...getApiActionTypes('FETCH_FAVORITE_ACCOUNT_HOMES'),
  ...getApiActionTypes('FETCH_RESIDENT_FLOWS'),
  ...getApiActionTypes('FETCH_TOUR_REQUESTS'),
  CURRENT_TASK_CLEAR: 'CURRENT_TASK_CLEAR',
};

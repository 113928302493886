import { useContext } from 'react';
import { AnimationContext } from './AnimationContext.js';

function useAnimationContext() {
  const animationContext = useContext(AnimationContext);
  if (typeof animationContext === undefined) {
    throw new Error('useAnimationContext must be used within an AnimationContext');
  }
  return animationContext;
}

export { useAnimationContext };

import { useState, useRef, useCallback, useEffect } from 'react';

function usePagination({
  pageIndex: initialPageIndex = 1,
  pageSize: initialPageSize = 20,
  initialItems = [],
  restParams,
  fetch
}) {
  const [items, setItems] = useState(initialItems);
  const pageIndex = useRef(initialPageIndex);
  const pageSize = useRef(initialPageSize);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMorePages, setHasMorePage] = useState(false);
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const queryParams = new URLSearchParams();
    queryParams.set('pageIndex', String(pageIndex.current));
    queryParams.set('pageSize', String(pageSize.current));
    for (const [key, value] of Object.entries(restParams || {})) {
      queryParams.set(key, String(value));
    }
    const data = await fetch(queryParams.toString());
    setItems(prevState => data.items ? prevState?.concat(data.items) : prevState);
    setHasMorePage(Number(data.pageIndex) < Number(data?.totalPages));
    pageIndex.current = pageIndex.current + 1;
    setIsLoading(false);
  }, [fetch, restParams]);
  function loadMore() {
    fetchData();
  }
  function reset() {
    setIsLoading(true);
    setItems([]);
    pageIndex.current = initialPageIndex;
    pageSize.current = initialPageSize;
    fetchData();
  }
  useEffect(() => {
    reset();
  }, [restParams]);
  return {
    isLoading,
    items,
    hasMorePages,
    loadMore,
    reset
  };
}

export { usePagination };

import { extendTailwindMerge } from 'tailwind-merge';
import { palette, borderRadius, spacing, widths, heights, fontWeight, fontSize } from '../constants/theme.js';

const spacings = {
  ...spacing,
  ...widths,
  ...heights
};
const fontWeightKeys = Object.keys(fontWeight);
const fontSizeKeys = Object.keys(fontSize);
const twMerge = extendTailwindMerge({
  theme: {
    colors: Object.keys(palette),
    spacing: Object.keys(spacings),
    borderRadius: Object.keys(borderRadius)
  },
  classGroups: {
    'font-weight': [{
      font: fontWeightKeys
    }],
    'font-size': [{
      text: fontSizeKeys
    }]
  }
});

export { twMerge };

import FormLayout from 'layouts/FormLayout/FormLayout';
import { MoveOutOptions, RentalStatus } from 'models/enums';
import { getIsDayInThePast } from 'pages/OccupancyStandalone/consts';
import PropTypes from 'prop-types';
import { RENTAL_STATUS_TEXT } from 'strings/rental-status';
import { validFutureOrTodayMMDDYYDate, validFutureOrTodayMMYYDate } from 'utils/validation';
import { MoveOutDateRangeFields } from '../MoveOutDateRangeFields/MoveOutDateRangeFields';
import { MoveOutOptionsFields } from '../MoveOutOptionsFields/MoveOutOptionsFields';
import { MoveOutSpecificDateFields } from '../MoveOutSpecificDateFields/MoveOutSpecificDateFields';

const getMoveOutOptions = (isOwnerOccupied, onboardingInfo) => {
  const pronoun = isOwnerOccupied ? 'I' : 'They';

  const options = [
    {
      label: 'On A Specific Date',
      key: MoveOutOptions.OnSpecificDate,
    },
    {
      label: 'In A Certain Month',
      key: MoveOutOptions.InDateRange,
    },
  ];

  // Don't show already did option if date in the past, since it's already show in the plansHaveChanged Field
  if (!getIsDayInThePast(onboardingInfo)) {
    options.push({
      label: `${pronoun} already did!`,
      key: MoveOutOptions.AlreadyDid,
    });
  }

  return options;
};

export const StandaloneMoveOutSection = ({ name, onboardingInfo, rentalStatus }) => {
  const isOwnerOccupied = rentalStatus === RentalStatus.OwnerOccupied;

  return (
    <FormLayout.Section
      sectionTitle={
        isOwnerOccupied ? RENTAL_STATUS_TEXT.ownerOccupiedMoveout : RENTAL_STATUS_TEXT.familyOccupiedMoveout
      }
    >
      <MoveOutOptionsFields buttons={getMoveOutOptions(isOwnerOccupied, onboardingInfo)} name={name} />
      <MoveOutSpecificDateFields extraValidation={validFutureOrTodayMMDDYYDate} name={name} />
      <MoveOutDateRangeFields extraValidation={validFutureOrTodayMMYYDate} name={name} />
    </FormLayout.Section>
  );
};

StandaloneMoveOutSection.propTypes = {
  name: PropTypes.string.isRequired,
  rentalStatus: PropTypes.oneOf([RentalStatus.OwnerOccupied, RentalStatus.FamilyOccupied]),
  onboardingInfo: PropTypes.object.isRequired,
};

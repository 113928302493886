import { IDENTITY_VERIFICATION_BANNER_STRINGS } from './IdentityVerificationBanner.strings.js';

const IDENTITY_VERIFICATION_SUPPORTED_DOCUMENTS = [{
  src: 'id_passport.svg',
  title: IDENTITY_VERIFICATION_BANNER_STRINGS.passport
}, {
  src: 'id_greencard.svg',
  title: IDENTITY_VERIFICATION_BANNER_STRINGS.permanent
}, {
  src: 'id_driver.svg',
  title: IDENTITY_VERIFICATION_BANNER_STRINGS.license
}, {
  src: 'id_state.svg',
  title: IDENTITY_VERIFICATION_BANNER_STRINGS.state
}, {
  src: 'id_visa.svg',
  title: IDENTITY_VERIFICATION_BANNER_STRINGS.visa
}, {
  src: 'id_ead.svg',
  title: IDENTITY_VERIFICATION_BANNER_STRINGS.ead
}];

export { IDENTITY_VERIFICATION_SUPPORTED_DOCUMENTS };

import { useState, useEffect } from 'react';

function useIsMobileDevice() {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const checkIfMobileDevice = () => {
      const userAgent = navigator.userAgent;
      if (/android/i.test(userAgent)) {
        return true;
      }
      if (/iPad|iPhone|iPod/.test(userAgent)) {
        return true;
      }
      return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    };
    setIsMobile(checkIfMobileDevice());
  }, []);
  return isMobile;
}

export { useIsMobileDevice };

const BREAKPOINTS = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl'
};
const BREAKPOINTS_WIDTHS = {
  XS: 0,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200
};

export { BREAKPOINTS, BREAKPOINTS_WIDTHS };

const INTERCOM_CONFIG = {
  homeowner: {
    // TODO: Marked for deletion! Keeping for documentation purposes, in case we ever bring scheduling back
    schedule: [{
      day: 1,
      openHour: 8,
      openMinuts: 30,
      closeHour: 20,
      closeMinutes: 0
    }, {
      day: 2,
      openHour: 8,
      openMinuts: 30,
      closeHour: 20,
      closeMinutes: 0
    }, {
      day: 3,
      openHour: 8,
      openMinuts: 30,
      closeHour: 20,
      closeMinutes: 0
    }, {
      day: 4,
      openHour: 8,
      openMinuts: 30,
      closeHour: 20,
      closeMinutes: 0
    }, {
      day: 5,
      openHour: 8,
      openMinuts: 30,
      closeHour: 20,
      closeMinutes: 0
    }, {
      day: 6,
      openHour: 9,
      openMinuts: 0,
      closeHour: 15,
      closeMinutes: 0
    }, {
      day: 7,
      openHour: 9,
      openMinuts: 0,
      closeHour: 15,
      closeMinutes: 0
    }],
    isEnabled: true
  },
  setupFlow: {
    schedule: [{
      day: 1,
      openHour: 9,
      openMinuts: 0,
      closeHour: 20,
      closeMinutes: 0
    }, {
      day: 2,
      openHour: 9,
      openMinuts: 0,
      closeHour: 20,
      closeMinutes: 0
    }, {
      day: 3,
      openHour: 9,
      openMinuts: 0,
      closeHour: 20,
      closeMinutes: 0
    }, {
      day: 4,
      openHour: 9,
      openMinuts: 0,
      closeHour: 20,
      closeMinutes: 0
    }, {
      day: 5,
      openHour: 9,
      openMinuts: 0,
      closeHour: 20,
      closeMinutes: 0
    }],
    isEnabled: true
  }
};

export { INTERCOM_CONFIG };

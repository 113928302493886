const ENVIRONMENTS = {
  DEVELOPMENT: 'development',
  DEV1: 'dev1',
  DEV2: 'dev2',
  DEV3: 'dev3',
  DEV4: 'dev4',
  DEV5: 'dev5',
  DEV6: 'dev6',
  DEV7: 'dev7',
  DEV8: 'dev8',
  STAGING: 'staging',
  QA: 'qa',
  SANDBOX: 'sandbox',
  PRODUCTION: 'production'
};

export { ENVIRONMENTS };

import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { HomeownersSetupFlowPage } from 'accounts/pages/homeowners/setup-flow/setup-flow';
import Property from 'pages/Accounts/Pages/YourPortfolio/Properties/Property/Property';
import { selectAccountProperties } from 'store/redux/homeowner-accounts/selectors';

export function PropertyPagePicker() {
  const { propertyId, unitId } = useParams<Record<'propertyId' | 'unitId', string>>();
  const accountProperties = useSelector(selectAccountProperties);

  const property = accountProperties?.find((accountProperty) => accountProperty.basicInfo.propertyId === propertyId);

  if (!property) {
    return <Redirect to="/accounts/portfolio/properties" />;
  }

  const isSetupFlowComplete = property?.units?.every((unit) => unit.isSetupFlowCompleted);

  const isPostInspectionFlowComplete = property?.units?.every(
    (unit) => !unit.hasImprovementsFlowOpen && !unit.hasPricingFlowOpen
  );

  const isLandlordFlowComplete = property?.units?.every((unit) => !unit.hasLandlordFlowOpen);

  const isPreMoveOutFlowCompleted = property?.units?.every((unit) => !unit.hasHomeownerPreMoveOutFlowOpen);

  if (isSetupFlowComplete && isPostInspectionFlowComplete && isLandlordFlowComplete && isPreMoveOutFlowCompleted) {
    return <Property propertyId={propertyId} unitId={unitId} />;
  }

  return <HomeownersSetupFlowPage />;
}

import * as React from 'react';
import { useAnalytics } from './analytics-context.js';
import { useTrackingData } from './tracking-context.js';

function useTracking() {
  const parentData = useTrackingData();
  const {
    track: rootTrack
  } = useAnalytics();
  const track = React.useCallback((eventType, trackingData) => {
    let component = [];
    if (trackingData.component) {
      component = [trackingData.component];
      if (parentData.component) {
        component = [...parentData.component, trackingData.component];
      }
      trackingData.component = component.flat();
    }
    return rootTrack?.(eventType, {
      ...parentData,
      ...trackingData
    });
  }, [parentData, rootTrack]);
  return track;
}

export { useTracking };

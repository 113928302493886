import { parse, isValid, isFuture, differenceInYears } from 'date-fns';

function isEmpty(value) {
  return value === undefined || value === null || value === '' || Array.isArray(value) && value.length === 0 || typeof value === 'object' && Object.keys(value).length === 0;
}
const isFunction = value => !isEmpty(value) && typeof value === 'function';
// All the error messages are not real and we need to find a way to pass error messages from the outside
function composeValidators(...validators) {
  return value => {
    return validators.reduce((error, validator) => error || validator(value), undefined);
  };
}
function isEmail(value) {
  if (isEmpty(value) || !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]*[^.]([a-zA-Z0-9.-]*[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/i.test(value)) {
    return 'Invalid email address.';
  }
}
function isEmailIfExist(value) {
  if (!isEmpty(value) && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]*[^.]([a-zA-Z0-9.-]*[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/i.test(value)) {
    return 'Invalid email address.';
  }
}
function isRequired(value) {
  if (isEmpty(value)) {
    return 'This field is required.';
  }
}
function isChecked(value) {
  if (typeof value === 'boolean' && value) {
    return;
  }
  return 'Unchecked.';
}
/** Use it when we need to overwrite the default required message */
function isRequiredWithCustomMessage(message) {
  return params => {
    return isRequired(params) ? message : undefined;
  };
}
function isPhone(value) {
  // stolen from: https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s02.html
  const phoneRegex = /^\d{10}$/;
  if (isEmpty(value)) {
    return;
  }
  if (!phoneRegex.test(value)) {
    return 'Please check that the phone number you entered is\u00a0correct.';
  }
}
function minLength(len) {
  return value => {
    if (isEmpty(value)) {
      return;
    }
    if (isEmpty(value) || value?.length < len) {
      return 'Invalid string.';
    }
  };
}
function maxLength(len) {
  return value => {
    if (value.length > len) {
      return 'Invalid string.';
    }
  };
}
const isValidAddress = value => {
  if (isEmpty(value.city)) {
    return 'Invalid.';
  } else if (isEmpty(value.streetAddress)) {
    return 'Invalid.';
  } else if (isEmpty(value.zipcode)) {
    return 'Invalid.';
  }
};
function isInteger(value) {
  if (!isEmpty(value) && !Number.isInteger(Number(value))) {
    return 'Must be an integer.';
  }
}
function isCreditCard(value) {
  if (isEmpty(value)) {
    return 'Invalid.';
  }
  const isAmex = /^34/.test(value) || /^37/.test(value);
  const creditCardNumberLength = isAmex ? 15 : 16;
  const isInvalid = isInteger(value) || minLength(creditCardNumberLength)(value) || maxLength(creditCardNumberLength)(value);
  if (isInvalid) {
    return 'Invalid.';
  }
}
function isCreditCardCVC(value, creditCardNumber) {
  if (isEmpty(value)) {
    return 'Invalid.';
  }
  const isAmex = /^34/.test(creditCardNumber) || /^37/.test(creditCardNumber);
  const creditCardNumberLength = isAmex ? 4 : 3;
  const isInvalid = isInteger(value) || minLength(creditCardNumberLength)(value.trim()) || maxLength(creditCardNumberLength)(value.trim());
  if (isInvalid) {
    return 'Invalid.';
  }
}
function isDateMMDDYY(value) {
  if (isEmpty(value)) {
    return 'Verify the date format.';
  }
  const splittedValue = value.split('/');
  if (value.split('/').length === 3) {
    const [mm, dd, yy] = splittedValue;
    if (yy.length === 2) {
      const dateObj = parse(`20${yy}/${mm}/${dd}`, 'yyyy/MM/dd', new Date());
      if (!isValid(dateObj)) {
        return 'Verify the date format.';
      }
    } else {
      return 'Verify the date format.';
    }
  } else {
    return 'Verify the date format.';
  }
}
function isFutureDateMMDDYY(value) {
  const [mm, dd, yy] = value.split('/');
  const dateObj = parse(`20${yy}/${mm}/${dd}`, 'yyyy/MM/dd', new Date());
  if (!isFuture(dateObj)) {
    return 'The date is not in the future';
  }
}
function isDateMMYY(value) {
  if (isEmpty(value)) {
    return 'Verify the date format.';
  }
  const splittedValue = value.split('/');
  if (value.split('/').length === 2) {
    const [mm, yy] = splittedValue;
    if (yy.length === 2) {
      const dateObj = parse(`20${yy}/${mm}/01`, 'yyyy/MM/dd', new Date());
      if (!isValid(dateObj)) {
        return 'Verify the date format.';
      }
    } else {
      return 'Verify the date format.';
    }
  } else {
    return 'Verify the date format.';
  }
}
function isDateMMYYYY(value) {
  if (isEmpty(value)) {
    return 'Verify the date format.';
  }
  const splittedValue = value.split('/');
  if (value.split('/').length === 2) {
    const [mm, yyyy] = splittedValue;
    if (yyyy.length === 4) {
      const dateObj = parse(`${yyyy}/${mm}/01`, 'yyyy/MM/dd', new Date());
      if (!isValid(dateObj)) {
        return 'Verify the date format.';
      }
    } else {
      return 'Verify the date format.';
    }
  } else {
    return 'Verify the date format.';
  }
}
const isFutureDateMMYY = value => {
  const [mm, yy] = value.split('/');
  const dateObj = parse(`20${yy}/${mm}/01`, 'yyyy/MM/dd', new Date());
  if (!isFuture(dateObj)) {
    return 'The date is not in the future.';
  }
};
function isPastDateMMDDYY(value) {
  const dateObj = parse(value, 'MM/dd/yy', new Date());
  if (isFuture(dateObj)) {
    return 'The date is not in the past.';
  }
}
function isZipCode(value) {
  if (isEmpty(value)) {
    return 'Invalid.';
  }
  const isInvalid = Boolean(isInteger(value)) || value.length !== 5;
  if (isInvalid) {
    return 'Invalid.';
  }
}
function isGreaterThan(comparisonValue) {
  return value => {
    if (!Number.isNaN(value)) {
      if (Number(value) <= comparisonValue) {
        return 'The value is lower than expected.';
      }
    }
  };
}
function isSmallerThan(comparisonValue) {
  return value => {
    if (!Number.isNaN(value)) {
      if (Number(value) >= comparisonValue) {
        return 'The value is higher than expected.';
      }
    }
  };
}
const dateValidationMMYY = value => {
  if (!isEmpty(value) && value.toString().split('/').length === 2) {
    const [mm, yy] = value.toString().split('/');
    if (yy.length === 2) {
      const dateObj = new Date(`20${yy}/${mm}/01`);
      if (!isValid(dateObj)) {
        return 'Verify the date format.';
      }
    } else {
      return 'Verify the date format.';
    }
  } else {
    return 'Verify the date format.';
  }
};
function isFutureDateMMDDYYYY(value) {
  const dateObj = parse(value, 'MM/dd/yyyy', new Date());
  if (!isFuture(dateObj)) {
    return 'The date is not in the future.';
  }
}
function isPastDateMMDDYYYY(value) {
  const dateObj = parse(value, 'MM/dd/yyyy', new Date());
  if (isFuture(dateObj)) {
    return 'The date is not in the past.';
  }
}
function isKid(value) {
  const dateObj = parse(value, 'MM/dd/yyyy', new Date());
  const age = differenceInYears(new Date(), dateObj);
  if (age >= 18) {
    return 'Verify the date format.';
  }
}
function isSSN(value) {
  const ssnRegex = /^\d{9}$/;
  if (!ssnRegex.test(value)) {
    return 'Invalid SSN.';
  }
}
function isSSNWithLastDigits(value) {
  if (Boolean(value) && !/^[0-9]{3}\-?[0-9]{2}\-?[0-9]{4}$/i.test(value) && !/^XXXXX\-?[0-9]{4}$/i.test(value)) {
    return 'Invalid SSN.';
  }
}

export { composeValidators, dateValidationMMYY, isChecked, isCreditCard, isCreditCardCVC, isDateMMDDYY, isDateMMYY, isDateMMYYYY, isEmail, isEmailIfExist, isEmpty, isFunction, isFutureDateMMDDYY, isFutureDateMMDDYYYY, isFutureDateMMYY, isGreaterThan, isInteger, isKid, isPastDateMMDDYY, isPastDateMMDDYYYY, isPhone, isRequired, isRequiredWithCustomMessage, isSSN, isSSNWithLastDigits, isSmallerThan, isValidAddress, isZipCode, maxLength, minLength };

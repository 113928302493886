// TODO: Regions: Fix it.
import { getOfficeAddress } from 'consts/address';

export const PROPERTY_INSURANCE_STRINGS = {
  title: 'Do you have landlord insurance yet?',
  'liability.title': 'Are you covered for at least $300k in personal liability?',
  'liability.no_condition_title': 'Belong requires homeowners be insured for the following:',
  'liability.no_condition_sections': [
    'Premises liability insurance of at least $300,000.',
    'Property damage insurance equal to the full replacement cost of your home and all of your personal property.',
    'Additional coverage for rent loss in the event that your home becomes uninhabitable.',
    'Adequate worker’s compensation insurance and coverage for any employees or contractors you employ outside of Belong.',
  ],
  'liability.no_conditions_bottom_1':
    'The full requirements are in the Owner’s Responsibilities section of your management agreement.',
  'liability.no_conditions_bottom_2':
    "Looking for a provider that meets these requirements? Just ask, and we'll get you insured through our cost effective <span>partner Obie.</span>",
  'liability.interested_party.title':
    'Have you added Belong to your policy as an Additional <span>Insured Party?</span>',
  'liability.interested_party.custom_option_1': 'Yes, way <span>ahead of you!</span>',
  'liability.interested_party.custom_option_2': 'No, not yet.',
  upload_pdf: 'Please provide us with proof <span>of insurance.</span>',
  not_added_belong: `No problem! Please inform your insurance company, and add BELONG HOME INC as an Additional Insured Party. Our corporate address is ${getOfficeAddress()}. Once you've done that, please send us the certificate of insurance.`,
};

export const TO_DO_STRINGS_MAP = {
  SetupMaintenancePaymentMethod: 'ADD MAINTENANCE PAYMENT METHOD',
  SetupRentPaymentMethod: 'ADD RENT PAYMENT METHOD',
  SetupEarningsPaymentMethod: 'ADD EARNINGS ACCOUNT',
  SetupProfile: 'COMPLETE YOUR PROFILE',
  HomeownerSetupFlowOpen: 'SET UP YOUR HOME',
  PostInspectionFlowOpen: 'REVIEW YOUR POST-INSPECTION PROPOSAL',
  MoveOutFlowOpen: 'REVIEW YOUR MOVE OUT INSPECTION RESULTS', // UI only
  AgreementFlowOpen: 'ACCEPT INVITATION TO JOIN BELONG',
  PendingLeaseSignature: 'SIGN LEASE',
  InitialPriceReportReview: 'REVIEW ESTIMATED RENT PRICE',
  WealthAnalysisFlowOpen: 'COMPLETE YOUR COMPLIMENTARY WEALTH\u00a0ANALYSIS FOR {address}',
  // TODO: Update once Randy provides design, could or could not be final`
  W9FlowOpen: 'UPDATE YOUR W9 LEGAL INFO',
};
